import userAnytimeConsultationApi from "../../../api/users/anytime_consultations";
import * as mutationTypes from "../../mutation-types";

const state = {
  userAnytimeConsultations: []
};

const getters = {
  userAnytimeConsultations: state => state.userAnytimeConsultations
};

const actions = {
  fetchUserAnytimeConsultations({ commit }, params) {
    return new Promise((resolve) => {
      userAnytimeConsultationApi.fetchUserAnytimeConsultations(userAnytimeConsultations => {
        commit(mutationTypes.ASSIGN_USER_ANYTIME_CONSULTATIONS, { userAnytimeConsultations });
        resolve();
      }, params);
    });
  },
  clearUserAnytimeConsultations({ commit }) {
    let userAnytimeConsultations = [];
    commit(mutationTypes.ASSIGN_USER_ANYTIME_CONSULTATIONS, { userAnytimeConsultations });
  },
};

const mutations = {
  [mutationTypes.ASSIGN_USER_ANYTIME_CONSULTATIONS](state, { userAnytimeConsultations }) {
    state.userAnytimeConsultations = userAnytimeConsultations;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
