<template>
  <div>
    <div class="diagnosis-form">
      <v-textarea
        v-model="karte.icd10BranchName"
        variant="outlined"
        name="diagnosis"
        required
        label="暫定診断（必須）*"
        rows="2"
        :readonly="true"
        :disabled="!active || noAdvice != false"
        class="pt-1 pb-0"
        @click="open"
      />
      <button
        type="button"
        :disabled="!active"
        class="diagnosis-delete-btn"
        @click="clearDiagnosis()"
      >
        選択解除
      </button>
    </div>

    <v-dialog
      v-model="viewModal"
      name="diagnosis"
      width="auto"
    >
      <div
        class="panel"
        @click.stop
      >
        <div class="modal-header">
          <h3>暫定診断</h3>
          <span>※メインの相談を1つ選択、「疑い」でも選択</span>
          <span
            class="close"
            @click="close"
          >X</span>
        </div>
        <div class="panel-body">
          <div
            v-for="frequentCategory in frequentCategories"
            class="panel-box"
          >
            <h4>{{ frequentCategory.sub_category }}</h4>
            <div class="sub_category_branches">
              <div v-for="subCategory in frequentCategory.branches">
                <h5 v-if="subCategory.sub_sub_category">
                  ＜{{ subCategory.sub_sub_category }}＞
                </h5>
                <ul class="item-list">
                  <li v-for="branch in subCategory.branches">
                    <span @click="onSelectDiagnosis(branch)">{{ branch.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="notes">
          <h4>備考</h4>
          <ul class="notice-list">
            <div>
              <li
                v-for="noteCategoryBranch in noteCategoryBranches"
                class="notice-text"
              >
                {{ noteCategoryBranch.note_description }}は
                <span v-for="(branch, index) in noteCategoryBranch.branches">
                  <span v-if="index != 0"> または</span>
                  <span
                    class="selectable-text"
                    @click="onSelectDiagnosis(branch)"
                  >{{ branch.name }}</span>
                </span>
                を選択してください。
              </li>
            </div>
          </ul>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "MidwifeDiagnosis",
  model: {
    prop: "karte"
  },
  props: {
    active: Boolean,
    karte: Object,
    noAdvice: Boolean,
    modelValue: Object,
  },
  data: () => ({
    frequentCategories: [],
    noteCategoryBranches: [],
    viewModal: false,
  }),
  created () {
    axios.get("/api/v1/icd10/midwife/frequent_categories", {}).then(response => {
      this.frequentCategories = response.data.frequent_categories;
      this.noteCategoryBranches = response.data.note_branches;
    }).catch(error => {
      console.log(error);
    });
  },
  methods: {
    onSelectDiagnosis(item) {
      this.karte.branch_id = item.id;
      this.karte.icd10BranchName = item.name;
      this.close();
    },
    clearDiagnosis() {
      this.karte.branch_id = null;
      this.karte.icd10BranchName = null;
    },
    open () {
      this.viewModal = true;
    },
    close () {
      this.viewModal = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.diagnosis-form {
  position: relative;
  .diagnosis-delete-btn {
    position: absolute;
    bottom: 1px;
    right: 0;
    color: #C0C0C0;
    &:hover {
      cursor: pointer;
      color: #CDCDCD;
    }
    &:disabled {
      display: none;
    }
  }
}
.panel {
  min-width: 850px;
  min-height: 600px;
  background: #fff;
  padding: 0;

  .modal-header {
    position: relative;
    width: 100%;
    background-color: #e0e0e0;
    padding: 5px;
    margin-bottom: 10px;
    text-align: center;

    h3 {
      display: inline;
      margin-right: 5px;
    }
    .close {
      position: absolute;
      font-size: 150%;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }
  }
  h4 {
    text-align: center;
  }
}

.panel-body {
  padding: 5px 20px;
  display: flex;
  flex-direction: row;
}

.panel-box {
  margin: 10px;
  height: 350px;

  h4 {
    text-align: center;
    background-color: #fcf0f4;
  }
}

.panel-box:nth-child(1) {
  width: 33%;
}

.panel-box:nth-child(2) {
  width: 67%;
}

.sub_category_branches {
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.item-list {
  margin-top: 5px;
  padding-left: 24px;

  li {
    margin-bottom: 3px;

    span {
      color: #4372c4;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.notes {
  margin-left: 25px;
  margin-right: 25px;
  background-color: #fef2d1;

  h4 {
    padding: 10px;
  }

  .notice-list {
    color: #ff6b81;
    padding-bottom: 10px;
    padding-left: 24px;
  }

  .notice-text {
    padding: 5px;
  }

  .selectable-text {
    color: #4372c4;
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
