import shiftApi from "../../api/shifts";
import * as mutationTypes from "../mutation-types";


const state = {
  shifts: {},
  shiftsUpdateResult: false,
  shiftsUpdateErrorDetail: "",
  shiftsUpdateSkippings: []
};


const getters = {
  shifts: state => state.shifts,
  shiftsUpdateErrorDetail: state => state.shiftsUpdateErrorDetail,
  shiftsUpdateSkippings: state => state.shiftsUpdateSkippings,
};


const actions = {
  fetchShiftsOfDay({ commit }, params) {
    return new Promise((resolve, reject) => {
      shiftApi.fetchShiftsOfDay(shifts => {
        commit(mutationTypes.ASSIGN_SHIFTS, { shifts });
        resolve();
      }, params);
    });
  },

  updateShifts({ commit }, params) {
    return new Promise((resolve, reject) => {
      shiftApi.updateShifts(
        params,
        (data) => {
          let shiftsUpdateErrorDetail = "";
          commit(mutationTypes.SHIFTS_UPDATE_ERROR_DETAIL, { shiftsUpdateErrorDetail });
          let skippingData = data.skippings;
          commit(mutationTypes.SHIFTS_UPDATE_SKIPPINGS, { skippingData });
          resolve();
        },
        (error) => {
          let shiftsUpdateErrorDetail = error.response.data["message"];
          commit(mutationTypes.SHIFTS_UPDATE_ERROR_DETAIL, { shiftsUpdateErrorDetail });
          reject();
        });
    });
  },

  clearSkippings({ commit }) {
    // 更新スキップ情報のクリア
    let skippingData = [];
    commit(mutationTypes.SHIFTS_UPDATE_SKIPPINGS, { skippingData });
  }
};


const mutations = {
  [mutationTypes.ASSIGN_SHIFTS](state, { shifts }) {
    state.shifts = shifts;
  },
  [mutationTypes.SHIFTS_UPDATE_ERROR_DETAIL](state, { shiftsUpdateErrorDetail }) {
    state.shiftsUpdateErrorDetail = shiftsUpdateErrorDetail;
  },
  [mutationTypes.SHIFTS_UPDATE_SKIPPINGS](state, {  skippingData }) {
    state.shiftsUpdateSkippings =  skippingData ;
  }
};


export default {
  state,
  getters,
  actions,
  mutations
};
