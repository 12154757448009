<template>
  <v-container class="pl-0 pr-0 pl-sm-6 pr-sm-6">
    <v-card
      variant="outlined"
      tile
      class="pa-3"
    >
      <v-card-item class="py-1">
        <p class="text-md-center">
          関連記事（適宜ユーザーにご紹介ください）
        </p>
      </v-card-item>
      <v-card-item>
        <ul style="list-style: inside;">
          <template v-for="(journal, idx) in recommendJournals">
            <li
              v-if="showAll(idx)"
              :key="idx"
            >
              {{ journal.title }} <a
                target="_blank"
                :href="journal.url"
                style="text-decoration: none"
              >ページを見る</a> |  <a
                class="text-decoration-none"
                href="#"
                @click.prevent.stop="copyLink(journal.url)"
              >リンクをコピー</a>
            </li>
          </template>
        </ul>
        <div
          v-if="recommendJournals.length > 3 && showAllFlg == false"
          class="text-md-center"
          style="padding-top: 8px"
        >
          <a
            class="pink--text"
            @click="toggle()"
          ><span class="pr-3">▼</span>もっと見る</a>
        </div>
        <div
          v-if="showAllFlg == true"
          class="text-md-center"
          style="padding-top: 8px"
        >
          <a
            class="pink--text"
            @click="toggle()"
          ><span class="pr-3">▲</span>閉じる</a>
        </div>
      </v-card-item>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RecommendJournals",
  data: () => ({
    showAllFlg: false
  }),
  computed: {
    ...mapGetters([
      "recommendJournals",
    ]),
  },
  methods: {
    toggle: function() {
      this.showAllFlg = !this.showAllFlg;
    },
    showAll: function(index) {
      if(this.showAllFlg){
        return true;
      } else {
        return index < 3;
      }
    },
    copyLink: function(url) {
      document.addEventListener("copy", function(e) {
        e.clipboardData.setData("text/plain", url);
        e.preventDefault();
      },
      {once:true}
      );
      document.execCommand("copy");
    }
  },
};
</script>
