import googleLogindApi from "../../api/google_login";
import * as mutationTypes from "../mutation-types";
import router from "../../routes/index";

const actions = {
  loginByGoogleIdToken ({ commit, dispatch, rootState }, params) {
    return new Promise((resolve) => {
      googleLogindApi.loginByGoogleIdToken(loggedIn => {
        commit(mutationTypes.ASSIGN_LOGGED_IN, { loggedIn });
        dispatch("fetchCurrentDoctor", null, { root: true }).then(()=> {
          // 権限によりログイン先が変わる
          router.push(rootState.sessions.currentDoctor.homeUrl);
        });
        resolve();
      }, params);
    });
  }
};

export default {
  actions
};
