const context = new AudioContext();
export default {
  methods: {
    soundNotify(){
      try {
        var audio = document.createElement("audio");
        audio.setAttribute("src", "/notice.mp3");
        audio.setAttribute("controls", true);
        audio.style.display = "none";

        audio.addEventListener("loadstart", function() {
          // Create the instance of MediaElementAudioSourceNode
          var source = context.createMediaElementSource(audio);
          source.connect(context.destination);
        }, false);
        audio.play();
      } catch(e) {
        console.log(e);
      }
    },
  }
};
