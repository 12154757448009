<template>
  <div v-html="parseScoreObj" />
</template>

<script>
function recursive(id, i, scoreObj) {
  if (Array.isArray(scoreObj) && scoreObj.length === 0) {
    return "";
  }

  let lowerText = "";
  if(scoreObj["details"].length > 0){
    let j = 1;
    lowerText = "<div class='score-explanation-content pl-3'>";
    scoreObj["details"].forEach(lowerScoreObj => {
      lowerText += recursive(id, i * 10 + j, lowerScoreObj);
      j = j + 1;
    });
    lowerText = lowerText + "</div>";
  }

  let label = "";
  let checked = "";
  if (i == 1){
    label = "Score：";
  }
  else if(i < 10000){
    checked = "checked";
  }

  let description = scoreObj["description"];
  [
    { re: /sum of/, text: "" },
    { re: /script score function,.*'additional_details_vector.*$/, text: "質問文の類似度(embedding)" },
    { re: /user_interest_id/, text: "気になっていること" },
    { re: /child_age_rank/, text: "子供の年齢" },
  ].forEach(item => {
    description = description.replace(item.re, item.text);
  });

  return `
<input id="score-explanation-check-${id}-${i}" class="score-explanation-check" type="checkbox" ${checked} />
<label class="score-explanation-label" for="score-explanation-check-${id}-${i}">${label}${scoreObj["value"]} ${description}</label>
${lowerText}
`;
}

export default {
  name: "ScoreExplanation",
  props: {
    scoreObj: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    }
  },
  computed: {
    parseScoreObj: function () {
      return recursive(this.id, 1, this.scoreObj);
    }
  }
};
</script>

<style lang="scss">
.score-explanation-check{
  display: none;
}
.score-explanation-content{
  display: none;
}

.score-explanation-check:checked + .score-explanation-label + .score-explanation-content{
  display: flex;
  flex-direction: column;
}

.score-explanation-label::before{
  content: "⚪︎ ";
  display: inline;
}

.score-explanation-check:checked + .score-explanation-label:has(+ .score-explanation-content)::before{
  content: "▽ ";
  display: inline;
}

.score-explanation-check:not(:checked) + .score-explanation-label:has(+ .score-explanation-content)::before{
  content: "▷ ";
  display: inline;
}
</style>