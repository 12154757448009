import anytimeConsultationShiftApi from "../../api/anytime_consultation_shifts";
import * as mutationTypes from "../mutation-types";

const state = {
  anytimeConsultationShifts: [],
  anytimeConsultationShiftsUpdateErrorDetail: "",
  anytimeConsultationShiftsUpdateSkippings: []
};

const getters = {
  anytimeConsultationShifts : state => state.anytimeConsultationShifts,
  anytimeConsultationShiftsUpdateErrorDetail : state => state.anytimeConsultationShiftsUpdateErrorDetail,
};

const actions = {
  fetchAnytimeConsultationShifts({ commit }, params) {
    return new Promise((resolve) => {
      anytimeConsultationShiftApi.fetchAnytimeConsultationShifts(anytimeConsultationShifts => {
        commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION_SHIFTS, { anytimeConsultationShifts });
        resolve();
      }, params);
    });
  },

  updateAnytimeConsultationShifts({ commit }, params) {
    return new Promise((resolve, reject) => {
      anytimeConsultationShiftApi.updateShifts(
        params,
        (data) => {
          let shiftsUpdateErrorDetail = "";
          commit(mutationTypes.AC_SHIFTS_UPDATE_ERROR_DETAIL, { shiftsUpdateErrorDetail });
          let skippingData = data.skippings;
          commit(mutationTypes.AC_SHIFTS_UPDATE_SKIPPINGS, { skippingData });
          resolve();
        },
        (error) => {
          let shiftsUpdateErrorDetail = error.response.data["message"];
          commit(mutationTypes.AC_SHIFTS_UPDATE_ERROR_DETAIL, { shiftsUpdateErrorDetail });
          reject();
        });
    });
  },
};

const mutations = {
  [mutationTypes.ASSIGN_ANYTIME_CONSULTATION_SHIFTS](state, { anytimeConsultationShifts }) {
    state.anytimeConsultationShifts = anytimeConsultationShifts;
  },
  [mutationTypes.AC_SHIFTS_UPDATE_ERROR_DETAIL](state, { shiftsUpdateErrorDetail }) {
    state.anytimeConsultationShiftsUpdateErrorDetail = shiftsUpdateErrorDetail;
  },
  [mutationTypes.AC_SHIFTS_UPDATE_SKIPPINGS](state, {  skippingData }) {
    state.anytimeConsultationShiftsUpdateSkippings =  skippingData ;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
