<template>
  <v-row
    v-if="child.id != null"
    class="child-special-note"
  >
    <v-col
      md="12"
      class="pt-2 pb-2"
    >
      <v-card>
        <v-toolbar
          color="#4D4D4D"
          height="32"
        >
          <v-toolbar-title>子の特記事項（※入力時は年月日を記載）</v-toolbar-title>
        </v-toolbar>
        <v-textarea
          v-if="active != null"
          v-model="child.notice"
          name="child-notice"
          hide-details
          :readonly="!active"
        />
        <v-textarea
          v-else
          v-model="child.notice"
          readonly
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ChildSpecialNote",
  props: {
    active: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters(["child"]),
  }
};
</script>

<style>
/* readonly 時の背景色を無透過の#FFFへ上書きするスタイル */
.child-special-note .v-input--readonly.v-text-field .v-field__overlay {
  background-color: #fff !important;
}
</style>

<style lang="scss" scoped>
/* override */
.v-toolbar-title {
  color: #FFF;
  font-size: 13px;
  font-weight: bold;
}
</style>
