import userKarteApi from "../../../api/users/kartes";
import * as mutationTypes from "../../mutation-types";

const state = {
  userKartes: []
};

const getters = {
  userKartes: state => state.userKartes
};

const actions = {
  fetchUserKartes({ commit }, params) {
    return new Promise((resolve) => {
      userKarteApi.fetchUserKartes(userKartes => {
        commit(mutationTypes.ASSIGN_USER_KARTES, { userKartes });
        resolve();
      }, params);
    });
  },
  clearUserKartes({ commit }) {
    let userKartes = [];
    commit(mutationTypes.ASSIGN_USER_KARTES, { userKartes });
  },
};

const mutations = {
  [mutationTypes.ASSIGN_USER_KARTES](state, { userKartes }) {
    state.userKartes = userKartes;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
