import axios from "./../index";

export default {
  fetchUserKartes(callback, params) {
    axios.get(`/api/v1/users/${params.userId}/kartes`, {
      cancelToken: params.cancelToken,
      validateStatus: function(status) { return status < 500; },
    }).then(response => {
      if (response.status === 404) {
        callback([]);
      } else {
        callback(response.data);
      }
    }).catch(error => {
      console.log(error);
    });
  }
};
