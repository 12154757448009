<template>
  <v-row class="parent-special-note">
    <v-col
      md="12"
      class="pt-2 pb-2"
    >
      <v-card>
        <v-toolbar
          color="#4D4D4D"
          height="32"
        >
          <v-toolbar-title>EPDS結果</v-toolbar-title>
        </v-toolbar>
        <v-card
          v-if="epdsAnswerList.length != 0"
          class="overflow-y-auto"
          max-height="400"
        >
          <v-card-item class="pa-0">
            <v-card
              v-for="answer in epdsAnswerList"
              :key="answer.id"
            >
              <v-card-text>
                <div>
                  <p class="mb-1 d-flex">
                    {{ answer.answered_at }}（産後{{ answer.weeks_after_childbirth }}時点）
                  </p>
                  <p class="mb-1">
                    回答：{{ answer.total_score }}点
                  </p>
                  <p
                    v-for="iq in answer.important_questions"
                    class="mb-1"
                  >
                    第{{ iq.question_no }}項目：{{ iq.score }}点
                  </p>
                </div>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer />
                <a @click="openEpds(answer.id)">
                  <v-btn
                    color="black"
                    style="background-color: #EC407A;"
                  >
                    閲覧
                    <v-icon icon="mdi-eye" />
                  </v-btn>
                </a>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-card-item>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EpdsList",
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      "epdsAnswerList",
    ]),
  },
  watch: {
    userId: {
      handler: "fetchEpdsAnswers",
      immediate: true
    }
  },
  unmounted() {
    this.$store.dispatch("clearEpdsAnswers");
  },
  methods: {
    openEpds: (id) => {
      window.open("/epds/" + id, "", "width=480, height=640");
    },
    fetchEpdsAnswers () {
      if (this.userId != null) {
        this.$store.dispatch("fetchEpdsAnswers", {
          user_id: this.userId
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/* override */
.v-toolbar-title {
  color: #FFF;
  font-size: 13px;
  font-weight: bold;
}
</style>
