import * as mutationTypes from "../mutation-types";

const state = {
  isSearching: false
};

const getters = {
  isSearching: state => state.isSearching
};

const actions = {
  assignIsSearching({ commit }, isSearching){
    commit(mutationTypes.ASSIGN_IS_SEARCHING, isSearching);
  }
};

const mutations = {
  [mutationTypes.ASSIGN_IS_SEARCHING](state, isSearching) {
    state.isSearching = isSearching;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};