<template>
  <div>
    <template v-if="consultation.department_type == 'pediatrician'">
      <template v-if="consultation.userType == 'ParentUser'">
        <pediatricianUserInfo
          :consultation="consultation"
        />
      </template>
      <template v-if="consultation.userType == 'YouthUser'">
        <pediatricianYouthUserInfo
          :consultation="consultation"
        />
      </template>
    </template>
    <template v-else>
      <obstetricsUserInfo
        :consultation="consultation"
      />
    </template>
  </div>
</template>

<script>
import PediatricianUserInfo from "./ac_user_info/Pediatrician.vue";
import ObstetricsUserInfo from "./ac_user_info/Obstetrics.vue";
import PediatricianYouthUserInfo from "./ac_user_info/PediatricianYouth.vue";

export default {
  name: "AnytimeConsultationUserInfo",
  components: {
    PediatricianUserInfo,
    ObstetricsUserInfo,
    PediatricianYouthUserInfo
  },
  props: ["consultation"],
};
</script>
