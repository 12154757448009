import anytimeConsultationApi from "../../api/anytime_consultations";
import * as mutationTypes from "../mutation-types";

const state = {
  anytimeConsultations: [],
  anytimeConsultation: {},
  anytimeConsultationAnswer: {},
  anytimeConsultationKartes: [],
  anytimeConsultationSiblingKartes: [],
  anytimeConsultationReferenceInfo: [],
  anytimeConsultationUpdateResult: true,
  anytimeConsultationSearchKeys: null,
  anytimeConsultationFilteredFlag: true,
  elasticsearchErrorDetail: ""
};

const getters = {
  anytimeConsultations: state => state.anytimeConsultations,
  anytimeConsultation: state => state.anytimeConsultation,
  anytimeConsultationAnswer: state => state.anytimeConsultationAnswer,
  anytimeConsultationKartes: state => state.anytimeConsultationKartes,
  anytimeConsultationSiblingKartes: state => state.anytimeConsultationSiblingKartes,
  anytimeConsultationReferenceInfo: state => state.anytimeConsultationReferenceInfo,
  anytimeConsultationUpdateResult : state => state.anytimeConsultationUpdateResult,
  anytimeConsultationSearchKeys : state => state.anytimeConsultationSearchKeys,
  anytimeConsultationFilteredFlag : state => state.anytimeConsultationFilteredFlag,
  elasticsearchErrorDetail: state => state.elasticsearchErrorDetail
};

const actions = {
  fetchAnytimeConsultations({ commit }, params) {
    // APIエラーの場合に前の情報が残らないようにする
    let anytimeConsultations = [];
    commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATIONS, anytimeConsultations);

    return new Promise((resolve) => {
      anytimeConsultationApi.fetchAnytimeConsultations(anytimeConsultations => {
        commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATIONS, { anytimeConsultations });
        resolve();
      }, params);
    });
  },

  fetchAssignedAnytimeConsultations({ commit }, params) {
    // APIエラーの場合に前の情報が残らないようにする
    let anytimeConsultations = [];
    commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATIONS, anytimeConsultations);

    return new Promise((resolve) => {
      anytimeConsultationApi.fetchAssignedAnytimeConsultations(anytimeConsultations => {
        commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATIONS, { anytimeConsultations });
        resolve();
      }, params);
    });
  },

  fetchCompletedAnytimeConsultations({ commit }, params) {
    // APIエラーの場合に前の情報が残らないようにする
    let anytimeConsultations = [];
    commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATIONS, anytimeConsultations);

    return new Promise((resolve) => {
      anytimeConsultationApi.fetchCompletedAnytimeConsultations(anytimeConsultations => {
        commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATIONS, { anytimeConsultations });
        resolve();
      }, params);
    });
  },

  searchAnytimeConsultations({ commit }, params) {
    return new Promise((resolve) => {
      commit(mutationTypes.ASSIGN_IS_SEARCHING, true );
      anytimeConsultationApi.searchAnytimeConsultations(anytimeConsultations => {
        commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATIONS, { anytimeConsultations });
        commit(mutationTypes.ASSIGN_IS_SEARCHING, false );
        resolve();
      }, params);
    });
  },

  elasticsearchAnytimeConsultations({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(mutationTypes.ASSIGN_IS_SEARCHING, true );
      anytimeConsultationApi.elasticsearchAnytimeConsultations(params,
        (anytimeConsultations) => {
          commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATIONS, { anytimeConsultations });
          commit(mutationTypes.ASSIGN_IS_SEARCHING, false );
          const elasticsearchErrorDetail = "";
          commit(mutationTypes.ASSIGN_ELASTICSEARCH_ERROR_DETAIL, { elasticsearchErrorDetail });
          resolve();
        }, 
        (error) => {
          commit(mutationTypes.ASSIGN_IS_SEARCHING, false );          
          const elasticsearchErrorDetail = error.response.data["message"];
          commit(mutationTypes.ASSIGN_ELASTICSEARCH_ERROR_DETAIL, { elasticsearchErrorDetail });
          reject();
        }
      );
    });
  },

  findAnytimeConsultation({ commit }, params) {
    return new Promise((resolve) => {
      anytimeConsultationApi.findAnytimeConsultation(anytimeConsultation => {
        commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION, { anytimeConsultation });
        resolve();
      }, params);
    });
  },

  findAnytimeConsultationAnswer({ commit }, params) {
    return new Promise((resolve) => {
      anytimeConsultationApi.findAnytimeConsultationAnswer(anytimeConsultationAnswer => {
        commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION_ANSWER, { anytimeConsultationAnswer });
        resolve();
      }, params);
    });
  },

  saveAnytimeConsultationAnswerDraft({ commit }, params) {
    return new Promise((resolve) => {
      anytimeConsultationApi.saveAnytimeConsultationAnswerDraft(anytimeConsultationAnswer => {
        commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION_ANSWER, { anytimeConsultationAnswer });
        resolve();
      }, params);
    });
  },

  completeAnytimeConsultationAnswer({ commit }, params) {
    return new Promise((resolve) => {
      anytimeConsultationApi.completeAnytimeConsultationAnswer(anytimeConsultationAnswer => {
        commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION_ANSWER, { anytimeConsultationAnswer });
        resolve();
      }, params);
    });
  },

  cancelAnytimeConsultationAnswer({ commit }, params) {
    return new Promise((resolve) => {
      anytimeConsultationApi.cancelAnytimeConsultationAnswer(
        params,
        anytimeConsultationAnswer => {
          var result = true;
          commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION_RESULT, { result });
          commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION_ANSWER, { anytimeConsultationAnswer });
          resolve();
        },
        () => {
          var result = false;
          commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION_RESULT, { result });
          resolve();
        });
    });
  },

  fetchAnytimeConsultationKartes({ commit }, params) {
    return new Promise((resolve) => {
      anytimeConsultationApi.fetchAnytimeConsultationKartes(anytimeConsultationKartes => {
        commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION_KARTES, { anytimeConsultationKartes });
        resolve();
      }, params);
    });
  },

  fetchAnytimeConsultationSiblingKartes({ commit }, params) {
    return new Promise((resolve) => {
      anytimeConsultationApi.fetchAnytimeConsultationSiblingKartes(anytimeConsultationSiblingKartes => {
        commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION_SIBLING_KARTES, { anytimeConsultationSiblingKartes });
        resolve();
      }, params);
    });
  },

  findAnytimeConsultationReferenceInfo({ commit }, params) {
    return new Promise((resolve) => {
      anytimeConsultationApi.findAnytimeConsultationReferenceInfo(anytimeConsultationReferenceInfo => {
        commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION_REFERENCE_INFO, { anytimeConsultationReferenceInfo });
        resolve();
      }, params);
    });
  },

  assignAnytimeConsultationSearchKeys({ commit }, searchKeys){
    commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION_SEARCH_KEYS, { searchKeys });
  },

  assignFilteredFlag({ commit }, filtered){
    commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION_FILTERED_FLAG, { filtered });
  },

  clearAnytimeConsultationObjects({ commit }) {
    let anytimeConsultation = {};
    commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION, { anytimeConsultation });
    let anytimeConsultationAnswer = {};
    commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION_ANSWER, { anytimeConsultationAnswer });
    let anytimeConsultationReferenceInfo = [];
    commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION_REFERENCE_INFO, { anytimeConsultationReferenceInfo });
  },

  clearAnytimeConsultationKartes({ commit }) {
    let anytimeConsultationKartes = [];
    commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION_KARTES, { anytimeConsultationKartes });
  },

  clearAnytimeConsultationSiblingKartes({ commit }) {
    let anytimeConsultationSiblingKartes = [];
    commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION_SIBLING_KARTES, { anytimeConsultationSiblingKartes });
  },

  deleteAnytimeConsultationAnswer({ commit }, params) {
    return new Promise((resolve) => {
      anytimeConsultationApi.deleteAnytimeConsultationAnswer(
        params,
        anytimeConsultationAnswer => {
          commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION_ANSWER, { anytimeConsultationAnswer });
          resolve();
        },
        errorData => {
          console.log(errorData["message"]);
          // ステータスが変更されている場合があるのでデータを再読込する
          anytimeConsultationApi.findAnytimeConsultationAnswer(anytimeConsultationAnswer => {
            commit(mutationTypes.ASSIGN_ANYTIME_CONSULTATION_ANSWER, { anytimeConsultationAnswer });
          }, params);
          resolve();
        });
    });
  },
};


const mutations = {
  [mutationTypes.ASSIGN_ANYTIME_CONSULTATIONS](state, { anytimeConsultations }) {
    state.anytimeConsultations = anytimeConsultations;
  },

  [mutationTypes.ASSIGN_ANYTIME_CONSULTATION](state, { anytimeConsultation }) {
    state.anytimeConsultation = anytimeConsultation;
  },

  [mutationTypes.ASSIGN_ANYTIME_CONSULTATION_ANSWER](state, { anytimeConsultationAnswer }) {
    state.anytimeConsultationAnswer = anytimeConsultationAnswer;
  },

  [mutationTypes.ASSIGN_ANYTIME_CONSULTATION_KARTES](state, { anytimeConsultationKartes }) {
    state.anytimeConsultationKartes = anytimeConsultationKartes;
  },

  [mutationTypes.ASSIGN_ANYTIME_CONSULTATION_SIBLING_KARTES](state, { anytimeConsultationSiblingKartes }) {
    state.anytimeConsultationSiblingKartes = anytimeConsultationSiblingKartes;
  },

  [mutationTypes.ASSIGN_ANYTIME_CONSULTATION_RESULT](state, { result }) {
    state.anytimeConsultationUpdateResult = result;
  },

  [mutationTypes.ASSIGN_ANYTIME_CONSULTATION_REFERENCE_INFO](state, { anytimeConsultationReferenceInfo }) {
    state.anytimeConsultationReferenceInfo = anytimeConsultationReferenceInfo;
  },

  [mutationTypes.ASSIGN_ANYTIME_CONSULTATION_SEARCH_KEYS](state, { searchKeys }) {
    state.anytimeConsultationSearchKeys = searchKeys;
  },

  [mutationTypes.ASSIGN_ANYTIME_CONSULTATION_FILTERED_FLAG](state, { filtered }) {
    state.anytimeConsultationFilteredFlag = filtered;
  },

  [mutationTypes.ASSIGN_ELASTICSEARCH_ERROR_DETAIL](state, { elasticsearchErrorDetail }) {
    state.elasticsearchErrorDetail = elasticsearchErrorDetail;
  }
};


export default {
  state,
  getters,
  actions,
  mutations
};
