<template>
  <div>
    <top-menu
      ref="top-menu"
      @search="changeDate"
      @logout="$emit('logout')"
    />
    <v-toolbar
      height="64px"
      color="transparent"
    >
      <v-container
        fluid
      >
        <v-row>
          <v-col md="12">
            <v-toolbar-items>
              <v-btn
                :to="{ path: '/shifts', query: { date: $route.query.date } }"
                size="large"
                variant="outlined"
                color="pink-lighten-1"
                class="mr-4 shift-edit-btn cancel-btn pr-8 pl-8"
              >
                キャンセル
                <v-icon
                  icon="mdi-reply"
                  size="x-large"
                />
              </v-btn>
              <v-btn
                size="large"
                class="shift-edit-btn save-btn pr-8 pl-8"
                @click="updateShiftWrapper()"
              >
                保存する
                <v-icon
                  icon="mdi-send"
                  size="x-large"
                />
              </v-btn>
              <div
                v-if="errorMessage != null"
                style="padding-left:20px"
                class="pink--text"
              >
                {{ errorMessage }}<br>
                {{ shiftsUpdateErrorDetail }}
              </div>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-container
      fluid
      class="pa-0"
    >
      <v-row justify="center">
        <v-col class="pt-0 pb-0">
          <v-data-table-virtual
            :headers="shifts.header"
            :header-props="{ nowrap: true }"
            :items="shifts.body"
            hide-default-footer
            disable-pagination
            hover
            class="elevation-1 shift-list"
          >
            <template #item="{item}">
              <tr
                :key="item.period"
                class="text-no-wrap"
              >
                <td>{{ item.period }}</td>
                <td v-for="shift in item.shifts_by_period">
                  <template v-if="filterdDoctors(shift.department_type) !== null">
                    <v-autocomplete
                      v-if="shift.status != 'reserved'"
                      :key="shift.id"
                      v-model="shift.doctor_id"
                      :name="`shifts[${shift.id}]`"
                      :items="filterdDoctors(shift.department_type)"
                      item-title="name"
                      item-value="id"
                      required
                      variant="underlined"
                      class="doctor-select"
                    />
                  </template>
                  <div v-if="shift.status == 'reserved'">
                    {{ findDoctorName(shift.doctor_id) }}
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table-virtual>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TopMenu from "./shared/TopMenu.vue";

export default {
  name: "Shift",
  components: {
    TopMenu
  },
  emits: ["logout"],
  computed: {
    ...mapGetters([
      "shifts",
      "doctors",
      "shiftsUpdateErrorDetail"
    ]),
    ...mapGetters({doctorsByDepartmentType: "doctorsByDepartmentType"}),
  },
  data: () => ({
    syounikaDoctors: null,
    obstetricsDoctors: null,
    midwifeDoctors: null,
    errorMessage: null,
    shiftsUpdateResult: true,
  }),
  methods: {
    ...mapActions(["updateShifts"]),
    updateShiftWrapper: function() {
      this.updateShifts(this.shifts).then(() => {
        this.$router.push({ path: "/shifts", query: { date: this.$route.query.date }});
      }).catch(() => {
        // 保存エラー
        this.shiftsUpdateResult = false;
        this.errorMessage = "シフトの変更に失敗しました。管理者にお問い合わせください。";
      });
    },
    changeDate(d) {
      this.$router.push({ name: "shift", query: { date: d } });
    },
    findDoctorName(doctor_id) {
      let index = this.doctors.findIndex((v) => v.id === doctor_id);
      if(index == -1) {
        return "";
      }
      return this.doctors[index]["name"];
    },
    filterdDoctors(department_type){
      switch(department_type){
      case 1:
        return this.syounikaDoctors;
      case 2:
        return this.obstetricsDoctors;
      case 3:
        return this.midwifeDoctors;
      }
    },
    createFilterdDoctors(){
      this.syounikaDoctors =  this.doctorsByDepartmentType(1);
      this.obstetricsDoctors = this.doctorsByDepartmentType(2);
      this.midwifeDoctors = this.doctorsByDepartmentType(3);
    }
  },
  watch: {
    "$route" () {
      this.$store.dispatch("fetchShiftsOfDay", {
        date: this.$route.query.date
      });
    }
  },
  created() {
    this.$store.dispatch("fetchAllDoctors").then(()=>{
      this.$store.dispatch("fetchShiftsOfDay", {
        date: this.$route.query.date
      });
    });
    this.$store.dispatch("fetchDoctorsByDepartmentType").then(()=>{
      this.createFilterdDoctors();
    });
    this.$store.dispatch("clearSkippings");
  }
};
</script>
<style lang="scss">
.shift-edit-btn.cancel-btn {
  font-size: 15px !important;
  background-color: #F6DDE6 !important;
}

.shift-edit-btn.save-btn {
  font-size: 15px !important;
  background-color: #ec407a !important;
  border-color: #ec407a !important;
  text-decoration: none !important;
  color: black !important;
}

.doctor-select{
  white-space: nowrap;
  min-width: 190px;
}

.shift-list{
  .v-select__slot {
    input {
      width: initial !important;
    }
  }
}
</style>
