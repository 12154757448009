import userApi from "../../api/users";
import * as mutationTypes from "../mutation-types";


const state = {
  users: [],
  user: {}
};


const getters = {
  users: state => state.users,
  user: state => state.user
};


const actions = {
  findUser({ commit }, params) {
    return new Promise((resolve) => {
      userApi.findUser(user => {
        commit(mutationTypes.ASSIGN_USER, { user });
        resolve();
      }, params);
    });
  },

  updateUser({ commit }, params) {
    return new Promise((resolve) => {
      userApi.updateUser(
        params,
        () => {
          resolve();
        },
        () => {
          // 必要に応じてエラー処理を追加
          resolve();
        });
    });
  },

  resetUser({ commit }) {
    let user = {};
    commit(mutationTypes.ASSIGN_USER, { user });
  },
};


const mutations = {
  [mutationTypes.ASSIGN_USERS](state, { users }) {
    state.users = users;
  },

  [mutationTypes.ASSIGN_USER](state, { user }) {
    state.user = user;
  }
};


export default {
  state,
  getters,
  actions,
  mutations
};
