<template>
  <v-container
    fluid
    style="background-color: #fdeef3"
  >
    <v-row class="ma-2 anytime-consultation-view">
      <v-col>
        <v-container
          fluid
          class="pa-2"
        >
          <v-row class="pa-0">
            <v-col
              sm="12"
              md="12"
              class="pb-2"
            >
              <span style="font-size: 1.5em; font-weight: bold;">{{ consultation.askedAt }}</span>
            </v-col>
          </v-row>
          <!-- 事前問診 -->
          <v-row>
            <v-col>
              <anytime-consultation-inquiry
                class="pa-0"
                :keywords="keywords"
              />
            </v-col>
          </v-row>
          <!--/ 事前問診 -->
          <v-row>
            <v-col class="pa-4">
              <span>
                <b>更新日時</b>
                <span class="pr-3">： {{ answer.updatedAt }}</span>
              </span>
              <span>
                <b>記入者</b>
                <span class="pr-3">： {{ answer.doctorName }}</span>
              </span>
              <br>
              <span>
                <b>主訴：</b>
                <span 
                  class="pr-3 highlight-text"
                  v-html="hightlightConvertedComplaint"
                />
              </span>
              <br>
              <span>
                <b>暫定診断</b>
                <span class="pr-3">： {{ answer.icd10BranchName }}</span>
              </span>
              <div
                ref="answertext"
                class="highlight-text-box"
                v-html="hightlightConvertedAnswer"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
function convertedHighlightText(original, keywords) {
  if (typeof original !== "string" || original.length == 0) {
    return "";
  }
  if (Array.isArray(keywords) && keywords.length >= 1) {
    keywords.forEach((str) => {
      original = original.replaceAll(str, "<em>" + str + "</em>");
    });
  }
  original = original.replaceAll(/\r\n/g, "<br>").replace(/\r/g, "<br>").replace(/\n/g, "<br>");
  return original;
}

import { mapGetters } from "vuex";
import AnytimeConsultationInquiry from "./shared/AnytimeConsultationInquiry.vue";

export default {
  name: "AnytimeConsultationView",
  components: {
    AnytimeConsultationInquiry
  },
  data: () => ({
    imageUrl: "",
    imageDialog: false,
  }),
  computed: {
    ...mapGetters({
      consultation: "anytimeConsultation",
      answer: "anytimeConsultationAnswer",
    }),
    keywords: function () {
      const query = this.$route.query.k;
      if(typeof query == "string"){
        return query.split(",");
      }
      return [];
    },
    hightlightConvertedComplaint: function () {
      return convertedHighlightText(this.$sanitize(this.answer.complaint), this.keywords);
    },
    hightlightConvertedAnswer: function () {
      return convertedHighlightText(this.$sanitize(this.answer.content), this.keywords);
    }
  },
  created() {
    this.$store.dispatch("findAnytimeConsultation", {
      id: this.$route.params.id
    });
    this.$store.dispatch("findAnytimeConsultationAnswer", {
      anytime_consultation_id: this.$route.params.id
    });
  },
  methods: {
    showMedia: function(e) {
      this.$data.imageDialog = true;
      this.$data.imageUrl = e.currentTarget.getAttribute("src");
    },
  }
};
</script>

<style lang="scss">
.anytime-consultation-view {
  border: solid 1px #bdbdbd;
  background-color: white;
  font-size: 14px;

  .v-text-field--outlined:not(.v-input--is-focused) fieldset,
  .v-text-field--outlined:not(.v-input--is-focused) .v-input__slot:hover fieldset {
    border: 2px solid rgba(0,0,0,.54);
  }

  .highlight-text {
    em {
      background-color: rgba(255, 255, 0, 0.4);
      font-style: normal;
      font-weight: bold;
    }
  }

  .highlight-text-box {
    width: 100%;
    height: 350px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 5px;
    overflow: scroll;
    padding: 16px 12px;
    color: rgba(0, 0, 0, 0.7);
    line-height: 18px;

    em {
      background-color: rgba(255, 255, 0, 0.4);
      font-style: normal;
      font-weight: bold;
    }
  }

}
</style>
