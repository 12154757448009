<template>
  <v-app>
    <v-app-bar>
      <v-toolbar-title>KP相談カルテ</v-toolbar-title>
    </v-app-bar>
    <v-main
      theme="light"
      class="c-login"
    >
      <v-container
        fluid
        class="fill-height pa-4"
      >
        <v-row
          align-content="center"
          justify="center"
          dense
        >
          <v-col
            cols="12"
            md="10"
            class="p-0"
          >
            <v-card class="login-card">
              <v-card-text>
                <v-row>
                  <v-col class="flex flex-col space-y-2 border-right-short" cols="12" md="6">
                    <h3 class="text-red bold-text pa-2">・Kids Public相談員の皆様へ</h3>
                    <v-card-actions
                      v-if="message != ''"
                      class="justify-center"
                    >
                      <div
                        class="notice login-failure text-red"
                        v-html="message"
                      ></div>
                    </v-card-actions>
                    <div v-if="emailLoginEnabled">
                      <h3 class="bold-text pa-2">メールアドレス・パスワードでログイン</h3>
                      <v-form>
                        <v-text-field
                          v-model="email"
                          class="my-2"
                          color="primary"
                          variant="underlined"
                          density="compact"
                          prepend-icon="mdi-account"
                          name="email"
                          label="メールアドレス"
                          type="text"
                        />
                        <v-text-field
                          v-model="password"
                          color="primary"
                          bg-color="white"
                          variant="underlined"
                          density="compact"
                          prepend-icon="mdi-lock"
                          name="password"
                          label="パスワード ※英数字20文字以上"
                          type="password"
                        />
                      </v-form>
                      <v-spacer />
                      <v-btn
                        class="mx-auto"
                        color="teal"
                        style="width: 200px; font-size: 20px;"
                        @click="onLogin()"
                      >
                        ログイン
                      </v-btn>
                      <div class="text-center pa-2">
                        <router-link class="text-teal" to="/password" style="text-decoration: none; font-size: 16px;">
                          パスワードをお忘れの方
                        </router-link>
                      </div>
                      <v-divider class="my-4"></v-divider>
                    </div>
                    <div class="align-center mb-2">
                      <h3 class="block bold-text pa-2">Googleアカウントでログイン</h3>
                      <p style="margin: 4px 0;">「ログイン」ボタンをクリックし、<span class="text-red bold-text">Kids Publicが発行してお渡したメールアドレス</span>を選択してください。</p>
                      <GoogleLogin @message-updated="updateMessage" />
                    </div>
                    <div>
                      <p class="bold-text">＜メールアドレスが分からない方へ＞</p>
                      <p>メールアドレスはKids Publicから発行したメールアドレス（名前.苗字@medxxxX-sxxxx.xxxx-xxxxx.co.jp)です。</p>
                    </div>
                    <div>
                      <p class="bold-text">＜パスワードが分からない方へ＞</p>
                      <p>パスワード入力画面に表示される「パスワードをお忘れの場合」のリンクよりログインをお試しください。</p>
                    </div>
                    <div>
                      <p class="bold-text">＜問題が解決せずカルテにログインできない場合＞</p>
                      <p>下記フォームよりお問い合わせください。</p>
                      <a href="https://docs.google.com/forms/d/e/1FAIpQLScQ6DgPrOLT36UHJ7-9Q9JiP-A6NR3Gza3UbZMN07gTq5C95Q/viewform" class="blue" style="text-decoration: underline;">→相談員用問い合わせフォーム</a>
                    </div>
                  </v-col>
                  <!-- Right section (information) -->
                  <v-col cols="12" md="6" class="info-section">
                    <h3 class="text-red bold-text pa-2">・このログイン画面にお心当たりのない方へ</h3>
                    <p>こちらは産婦人科・小児科オンラインの医療従事者のみが使用する機能です。</p>
                    <p>お困りの点、不明点がありましたらお問い合わせください。</p>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSeQIKiN1wcWuQ3UAje_vR8rCYSMf56djFf6yFRXHvgrVg1BJQ/viewform?c=0&w=1" class="blue" style="text-decoration: underline;">→産婦人科・小児科オンライン利用者様問い合わせフォーム</a>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GoogleLogin from "./GoogleLogin.vue";

export default {
  name: "App",
  components: {
    GoogleLogin
  },
  emits: ["setUpNotice", "setUpLogoutTimer"],
  data: () => ({
    email: "",
    password: "",
    redirectPath: "",
    message: ""
  }),
  computed: {
    ...mapGetters(["loggedIn", "emailLoginEnabled"]),
  },
  methods: {
    ...mapActions(["login", "fetchAuthSetting"]),
    onLogin: function() {
      this.login({ email: this.email,
        password: this.password,
        router: this.$router,
        redirectPath: this.redirectPath }
      ).then(() => {
        if(this.loggedIn == false){
          this.message = "ログインに失敗しました";
        } else {
          this.$emit("setUpNotice");
          this.$emit("setUpLogoutTimer");
        }
      });
    },
    updateMessage(newMessage) {
      this.message = newMessage;
    },
  },
  created() {
    this.redirectPath = this.$route.query.redirect_to;
  },
  mounted() {
    this.fetchAuthSetting();
  },
};
</script>

<style lang="scss">
.c-login {
  .login-card.v-card--variant-elevated, 
  .login-card.v-card--variant-flat {
    color: #4F4F4F !important;
  }
  .notice {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.8;
  }
  .login-failure {
    padding-left: 12px;
  }

  .v-field__input{
    padding: 8px 0;
  }

  .v-input--horizontal .v-input__prepend {
    margin-inline-end: 9px;
  }

  .v-btn {
    display: block;
    margin: 6px 8px;
    padding: 0 8px;
  }
  .btn-label .v-btn__content {
    font-size: 13px;
  }
  .bold-text {
    font-weight: bold;
  }

  .border-right-short {
    position: relative;
  }

  .border-right-short::after {
    content: '';
    position: absolute;
    right: 0;
    top: 2%;
    bottom: 2%;
    width: 1px;
    background-color: rgb(79, 79, 79);
    opacity: 0.12;
  }
}
</style>
