<template>
  <v-app>
    <v-app-bar>
      <v-toolbar-title>KP相談カルテ</v-toolbar-title>
    </v-app-bar>
    <v-main
      theme="light"
      class="c-password-edit"
    >
      <v-container
        fluid
        class="fill-height pa-4"
      >
        <v-row
          align-content="center"
          justify="center"
          dense
        >
          <v-col
            cols="12"
            md="5"
            class="p-0"
          >
            <router-view />
            <v-card
              class="elevation-12 password-edit-card"
            >
              <v-card-text>
                <v-row>
                  <v-col class="flex flex-col align-center justify-center">
                    <h2 class="bold-text text-center pa-4">パスワードの再設定</h2>
                    <div
                      v-if="passwordResetErrorDetail != ''"
                      class="pa-6 pb-10 notice error-message text-red"
                      v-html="passwordResetErrorDetail"
                    >
                    </div>
                    <v-form>
                      <v-text-field
                        v-model="password"
                        color="primary"
                        bg-color="white"
                        variant="underlined"
                        density="compact"
                        prepend-icon="mdi-lock"
                        name="password"
                        label="新しいパスワード※英数字20文字以上"
                        type="password"
                      />
                      <v-text-field
                        v-model="password_confirmation"
                        bg-color="white"
                        variant="underlined"
                        density="compact"
                        prepend-icon="mdi-lock"
                        name="password_confirmation"
                        label="新しいパスワード(確認)※英数字20文字以上"
                        type="password"
                      />
                    </v-form>
                    <v-btn
                      class="mx-auto mb-3"
                      color="teal"
                      style="width: 200px; font-size: 20px;"
                      @click="onResetPassword()"
                    >
                      保存する
                    </v-btn>
                    <v-btn
                      class="mx-auto"
                      style="width: 200px; font-size: 20px;"
                      @click="onResetPassword()"
                    >
                      <router-link to="/login" style="color: teal !important;">
                        キャンセル
                      </router-link>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<style>
.notice {
  font-size: .875rem;
}
.error-message {
  padding-left: 12px;
  white-space: pre-wrap;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    reset_password_token: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    password: "",
    password_confirmation: "",
  }),
  computed: {
    ...mapGetters(["passwordResetErrorDetail"]),
  },
  methods: {
    ...mapActions(["ResetPassword"]),
    onResetPassword() {
      this.ResetPassword({
        password: this.password,
        password_confirmation: this.password_confirmation,
        reset_password_token: this.reset_password_token
      }).then(() => {
        this.$emit("setUpNotice");
        this.$emit("setUpLogoutTimer");
      }
      );
    },
  },
};
</script>
<style lang="scss">
.c-password-edit {
  .password-edit-card.v-card--variant-elevated, 
  .password-edit-card.v-card--variant-flat {
    color: #4F4F4F !important;
  }
  .notice {
    font-size: 1rem;
    font-weight: bold;
  }
  .v-field__input{
    padding: 8px 0;
  }

  .v-input--horizontal .v-input__prepend {
    margin-inline-end: 9px;
  }

  .v-btn {
    height: 30px;
    display: flex;
    line-height: 1.5;
    align-items: center;
    justify-content: center;
  }
  .btn-label .v-btn__content {
    font-size: 13px;
  }
  .bold-text {
    font-weight: bold;
  }
}
</style>

