import axios from "axios";

export default {
  sendResetEmail(callback, params) {
    axios.post("/api/v1/doctors/password", {
      doctor: {
        email: params.email
      }
    }).then(() =>{
      callback(true);
    }).catch(error => {
      console.log(error);
      callback(false);
    });
  },

  ResetPassword(params, successCallback, failureCallback) {
    axios.patch("/api/v1/doctors/password", {
      doctor: {
        password: params.password,
        password_confirmation: params.password_confirmation,
        reset_password_token: params.reset_password_token
      }
    }).then(() =>{
      successCallback(true);
    }).catch(error => {
      console.log(error);
      failureCallback(error);
    });
  },
};


