<template>
  <div class="chat-logs-view pa-3 mr-4 mb-3">
    <div
      v-for="chatLog in chatLogs"
      class="pb-3"
    >
      <div
        v-if="chatLog.contentType != 'image'"
        class="message-area"
      >
        <div
          v-if="chatLog.content"
          class="sent-message"
          v-html="$sanitize(newLineCodeToBr(chatLog.content))"
        />
        <div
          v-if="chatLog.fileUrl && chatLog.contentType == 'pdf'"
          class="sent-file"
        >
          <a
            :href="`/api/v1/chat_logs/${chatLog.id}/media_download`"
            target="_blank"
          >
            <v-icon>picture_as_pdf</v-icon>
            {{ chatLog.fileName }}
          </a>
        </div>
      </div>
      <div
        v-if="chatLog.contentType == 'image'"
        class="sent-image pr-2"
      >
        <img
          v-if="chatLog.fileUrl"
          :src="chatLog.fileUrl"
          :alt="chatLog.fileName"
          :title="chatLog.fileName"
          class="filled-image secure-image"
        >
        <img
          v-if="chatLog.content"
          :src="chatLog.fileUrl"
          :alt="chatLog.fileName"
          :title="chatLog.fileName"
          class="filled-image secure-image"
        >
      </div>
      <span class="sender-info">{{ chatLog.senderName }} ({{ chatLog.sentAtDateTime }})</span>
      <v-divider />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import commonMixin from "../../mixins/chat_log_common.js";

export default {
  name: "ChatView",
  mixins: [commonMixin],
  props: {
    chatId: {
      type: Number,
      required: true
    },
    logType: {
      type: String,
      required: true
    },
  },
  computed: {
    ...mapGetters({
      chatLogs: "chatLogs",
    })
  },
  watch: {
    "$route" () {
      this.fetchData();
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData: function() {
      this.$store.dispatch("clearChatLogs");
      this.$store.dispatch("fetchChatLogs", {
        chatId: this.chatId,
        logType: this.logType,
      });
    }
  }
};
</script>
<style lang="scss">
/* override */
.chat-logs-view {
  border: 2px solid #C0C0C0;
  border-radius: 4px;
  overflow: scroll;
  height: 250px;
  min-height: 150px;
  resize: vertical;

  .filled-image {
    width: auto;
    height: 50px;
  }

  .sender-info{
    color: #999;
    font-size: 10px;
  }

  .sent-message {
    word-break: break-all;
  }
}
</style>
