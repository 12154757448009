<template>
  <div>
    <div>
      <b>ユーザID</b>
      <span class="pr-3">： {{ consultation.userId }}</span>
      <span v-if="consultation.needAttentionUser" class="btn-label">
        <v-chip color="pink" size="small" variant="outlined">運営特記事項あり</v-chip>
      </span>
    </div>
    <div>
      <span><b>クーポン名</b>： {{ consultation.userCouponName }}</span>
    </div>
    <div>
      <span><b>ユーザの性別</b>： {{ consultation.userGender }}</span>
    </div>
    <div>
      <span><b>相談対象者の生年月日</b>： {{ consultation.userBirthdate }}</span>
    </div>

    <div v-if="consultation.inPregnancy == 'pregnant'">
      <span
        ><b>出産予定日</b>： {{ consultation.lastDueDate }} ({{
          consultation.childAge
        }})</span
      >
      <br />
    </div>

    <div v-else-if="consultation.inPregnancy == 'postpartum'">
      <span
        ><b>最後の出産日</b>： {{ consultation.lastDueDate }} ({{
          consultation.childAge
        }})</span
      >
      <br />
    </div>

    <div v-else-if="consultation.inPregnancy == 'others'" />

    <span><b>妊娠回数</b>： {{ consultation.pastPregnancyNum }}</span> |
    <span><b>出産回数</b>： {{ consultation.pastDeliveryNum }}</span>
  </div>
</template>

<script>
export default {
  name: "AnytimeConsultationObstetricsUserInfo",
  props: {
    consultation: {
      type: Object,
      default: null
    },
  },
};
</script>
