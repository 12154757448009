import axios from "./index";

export default {
  fetchAnytimeConsultationShifts(callback, params) {
    axios.get("/api/v1/anytime_consultation_shifts", {
      params: {
        date: params.date,
      }
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  updateShifts(params, successCallback, failureCallback) {
    axios.put("/api/v1/anytime_consultation_shifts/bulk_update", {
      shifts: params
    }, {
      headers: { "Content-Type": "application/json" }
    }
    ).then(response => {
      successCallback(response.data);
    }).catch(error => {
      console.log(error);
      failureCallback(error);
    });
  },

  create(params, successCallback, failureCallback) {
    axios.post("/api/v1/anytime_consultation_shifts", {
      anytime_consultation_shift: params
    }, {
      headers: { "Content-Type": "application/json" }
    }
    ).then(response => {
      successCallback(response.data);
    }).catch(error => {
      console.log(error);
      failureCallback(error);
    });
  },

  update(params, successCallback, failureCallback) {
    axios.put("/api/v1/anytime_consultation_shifts/" + params.id, {
      anytime_consultation_shift: params
    }, {
      headers: { "Content-Type": "application/json" }
    }
    ).then(response => {
      successCallback(response.data);
    }).catch(error => {
      console.log(error);
      failureCallback(error);
    });
  },

  delete(params, successCallback, failureCallback) {
    axios.delete("/api/v1/anytime_consultation_shifts/" + params.id, {
      headers: { "Content-Type": "application/json" }
    }
    ).then(response => {
      successCallback(response.data);
    }).catch(error => {
      console.log(error);
      failureCallback(error);
    });
  },

  isOnShift(callback, params) {
    axios.get("/api/v1/anytime_consultation_shifts/on_shift", {
      params: {
        doctor_id: params.doctor_id
      }
    }).then(response => {
      callback(response.data);
      callback(response.data.on_shift);
    }).catch(error => {
      console.log(error);
    });
  },

  isNoticeExpiration(callback, params) {
    axios.get("/api/v1/anytime_consultation_shifts/notice_expiration", {
      params: {
        doctor_id: params.doctor_id
      }
    }).then(response => {
      callback(response.data);
      callback(response.data.notice_expiration);
    }).catch(error => {
      console.log(error);
    });
  }
};
