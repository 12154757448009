<template>
  <v-container grid-list-xs fluid class="pa-2">
    <v-row
      v-for="(reservationBlock, index) in reservationList"
      :key="index"
      noguide
    >
      <v-col cols="1" class="pa-2 pr-0">
        <v-card :flat="true" style="background-color: #fafafa">
          <v-card-text>
            <span v-text="reservationBlock.time" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-container fluid class="pt-2">
          <router-link
            v-for="reservation in reservationBlock.reservations"
            :key="reservation.id"
            :to="{
              path: 'karte',
              query: {
                room: reservation.roomSection,
                date: $route.query.date,
                reservation_id: reservation.id,
              },
            }"
            tag="v-col"
            class="flex xs12 ma-1 card--hover"
            style="border-radius: 2px"
          >
            <v-row noguide style="margin-bottom: 1px; background-color: #bdbdbd">
              <v-col
                sm="6"
                md="1"
                style="
                  border: solid 1px #bdbdbd;
                  padding: 1px;
                  padding-left: 2px;
                "
              >
                <v-card :flat="true" ripple height="100%">
                  <v-card-text>
                    <span v-text="reservation.department_name + ' 相談ルーム ' + reservation.room" />
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col
                sm="6"
                md="1"
                style="padding-top: 1px; padding-bottom: 1px"
              >
                <v-row noguide class="mt-0" style="height: 50%">
                  <v-col md="12" class="pa-0" style="border: solid 1px #bdbdbd">
                    <v-card :flat="true" ripple height="100%">
                      <v-card-text>
                        <span v-text="reservation.doctor" />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row noguide style="height: 50%">
                  <v-col
                    md="12"
                    height="100%"
                    class="pa-0"
                    style="border: solid 1px #bdbdbd"
                  >
                    <v-card :flat="true" ripple height="100%">
                      <v-card-text>
                        <span v-text="reservation.tool" />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                sm="12"
                md="10"
                style="
                  border: solid 1px #bdbdbd;
                  padding: 1px;
                  padding-right: 2px;
                "
              >
                <v-card :flat="true" ripple>
                  <v-card-text>
                    <div v-if="reservation.department_type == 1">
                      <div v-if="reservation.userType == 'ParentUser'">
                        <syounikaUserInfo
                          :reservation="reservation"
                        />
                      </div>
                      <div v-if="reservation.userType == 'YouthUser'">
                        <youthUserInfo
                          :reservation="reservation"
                        />
                      </div>
                    </div>
                    <div
                      v-if="
                        reservation.department_type == 2 ||
                        reservation.department_type == 3
                      "
                    >
                      <obstetricsUserInfo
                        :reservation="reservation"
                      />
                    </div>
                  </v-card-text>
                  <v-card-text>
                    <span v-text="reservation.description" />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </router-link>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import SyounikaUserInfo from "./shared/reservation_list/SyounikaUserInfo.vue";
import YouthUserInfo from "./shared/reservation_list/SyounikaYouthUserInfo.vue";
import ObstetricsUserInfo from "./shared/reservation_list/ObstetricsUserInfo.vue";

export default {
  name: "ReservationList",
  components: {
    SyounikaUserInfo,
    YouthUserInfo,
    ObstetricsUserInfo
  },
  computed: {
    ...mapGetters(["reservationList", "reservationTimeoutId", "currentDoctor"]),
  },
  watch: {
    $route() {
      this.startAutoReload();
    },
  },
  created() {
    this.startAutoReload();
  },
  unmounted() {
    // 自動更新を停止する
    clearInterval(this.reservationTimeoutId);
  },
  methods: {
    fetchReservations: function () {
      this.$store.dispatch("fetchReservations", {
        date: this.$route.query.date,
      });
    },
    startAutoReload: function () {
      // 以前設定されたtimeoutをクリア
      clearInterval(this.reservationTimeoutId);
      // 最初の1回の実行
      this.fetchReservations();
      // 30秒
      var timeoutId = setInterval(this.fetchReservations, 30 * 1000);
      this.$store.dispatch("assignReservationTimeoutId", timeoutId);
    },
  },
};
</script>

<style>
.back-pink {
  border-color: #ff6b7f;
}
.v-card__text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.v-btn {
  margin: 6px 8px;
  padding: 0 8px;
}
.btn-label .v-btn__content {
  font-size: 13px;
}
</style>
