<template>
  <v-row class="operator-special-note">
    <v-col
      md="12"
      class="pb-2"
    >
      <v-card>
        <v-toolbar
          color="#4D4D4D"
          height="32"
        >
          <v-toolbar-title>運営からの特記事項</v-toolbar-title>
        </v-toolbar>
        <v-textarea
          v-if="active != null"
          v-model="user.operatorNotice"
          name="operator-notice"
          hide-details
          :readonly="!active"
        />
        <v-textarea
          v-else
          v-model="user.operatorNotice"
          readonly
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OperatorSpecialNote",
  props: {
    active: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters(["user"]),
  }
};
</script>

<style>
/* readonly 時の背景色を無透過の#FFFへ上書きするスタイル */
.operator-special-note .v-input--readonly.v-text-field .v-field__overlay {
  background-color: #fff !important;
  opacity: 1 !important;
}
</style>

<style lang="scss" scoped>
/* override */
.v-toolbar-title {
  color: #FFF;
  font-size: 13px;
  font-weight: bold;
}
</style>
