import roomApi from "../../api/rooms";
import * as mutationTypes from "../mutation-types";


const state = {
  room: {}
};

const getters = {
  room: state => state.room,
};

const actions = {
  findRoom({ commit }, params) {
    return new Promise((resolve) => {
      roomApi.findRoom(room => {
        commit(mutationTypes.ASSIGN_ROOM, { room });
        resolve();
      }, params);
    });
  },
};

const mutations = {
  [mutationTypes.ASSIGN_ROOM](state, { room }) {
    state.room = room;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
