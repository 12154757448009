<template>
  <div class="acshift-value-edit">
    <div style="min-width: 140px">
      <v-autocomplete
        v-model="shift.doctor_id"
        :name="'doctor_' + shift.doctor_id"
        :items="doctorList"
        item-title="name"
        item-value="id"
        required
        variant="underlined"
        class="doctor-select"
      />
      <p
        v-if="shiftsUpdateResult === false"
      >
        {{ errorMessage }}<br>
        {{ anytimeConsultationShiftUpdateErrorDetail }}
      </p>
    </div>
    <div class="button-wrapper">
      <v-btn
        size="x-small"
        color="pink-lighten-1"
        class="shift-edit-btn text-black"
        @click="submitAcShift()"
      >
        保存する
        <v-icon
          icon="mdi-send"
          size="small"
        />
      </v-btn>
      <v-btn
        size="x-small"
        variant="outlined"
        color="pink-lighten-1"
        class="shift-edit-btn cancel-btn"
        @click="$emit('close')"
      >
        キャンセル
        <v-icon
          icon="mdi-reply"
          size="small"
        />
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AnytimeConsultationShiftValueEdit",
  props: {
    assignShift: {
      type: Object,
      required: true
    },
    startAt: {
      type: String,
      required: true
    },
    endAt: {
      type: String,
      required: true
    },
    doctors: {
      type: Array,
      required: true
    },
  },
  emits: ["close"],
  data: () => ({
    shift: {
      id: null,
      department_type: null,
      doctor_id: null,
      start_at: null,
      end_at: null
    },
    shiftsUpdateResult: true,
    errorMessage: null,
  }),
  computed: {
    ...mapGetters([
      "anytimeConsultationShiftUpdateErrorDetail"
    ]),
    doctorList: function () {
      if (this.assignShift.id === null) {
        // 登録の場合はdoctorsの中からdoctor_idが0（削除用）のdoctorを除外する
        return this.filterForCreate(this.doctors);
      } else {
        return this.doctors;
      }
    }
  },
  created() {
    this.initShift();
  },
  methods: {
    ...mapActions([
      "updateAnytimeConsultationShift",
      "deleteAnytimeConsultationShift",
      "createAnytimeConsultationShift"
    ]),
    submitAcShift() {
      if (this.assignShift.id === null) {
        // 登録のとき
        this.createAnytimeConsultationShift(this.shift).then(() => {
          this.$emit("close");
          this.$store.dispatch("fetchAnytimeConsultationShifts", {
            date: this.$route.query.date
          });
        }).catch(() => {
          // 保存エラー
          this.shiftsUpdateResult = false;
          this.errorMessage = "いつでも相談シフトの作成に失敗しました。管理者にお問い合わせください。";
        });
      } else {
        // 削除のとき
        if (this.shift.doctor_id === 0) {
          this.deleteAnytimeConsultationShift(this.shift).then(() => {
            this.$emit("close");
            this.$store.dispatch("fetchAnytimeConsultationShifts", {
              date: this.$route.query.date
            });
          }).catch(() => {
            // 保存エラー
            this.shiftsUpdateResult = false;
            this.errorMessage = "いつでも相談シフトの削除に失敗しました。管理者にお問い合わせください。";
          });
        } else {
          // 更新のとき
          this.updateAnytimeConsultationShift(this.shift).then(() => {
            this.$emit("close");
            this.$store.dispatch("fetchAnytimeConsultationShifts", {
              date: this.$route.query.date
            });
          }).catch(() => {
            // 保存エラー
            this.shiftsUpdateResult = false;
            this.errorMessage = "いつでも相談シフトの変更に失敗しました。管理者にお問い合わせください。";
          });
        }
      }
    },
    initShift() {
      this.shift.id = this.assignShift.id;
      this.shift.department_type = this.assignShift.department_type;
      this.shift.doctor_id = this.assignShift.doctor.id;
      this.shift.start_at = this.startAt;
      this.shift.end_at = this.endAt;
    },
    filterForCreate(doctors) {
      return doctors.filter(doctor=> doctor.id !== 0);
    }
  }
};
</script>

<style lang="scss">
.acshift-value-edit {
  display: flex;
  align-items: center;
}

.acshift-value-edit .button-wrapper {
  display: inline-flex;
  flex-direction: column;
}

.acshift-value-edit .button-wrapper .shift-edit-btn {
  display: flex;
  font-size: 15px;
}
</style>
