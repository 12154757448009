import axios from "axios";

const csrfDom = document.getElementsByName("csrf-token");

if(csrfDom.length > 0) {
  const token = csrfDom[0].getAttribute("content");
  axios.defaults.headers["X-CSRF-TOKEN"] = token;
}

axios.defaults.headers.common["Accept"] = "application/json";

export default axios;
