export default {
  data: () => ({
    // jsonBuilderでto_iしていないので未設定はnull
    outcomeOptions: [
      { key: "未設定", value: null },
      { key: "経過観察（増悪時/持続時は医療機関受診）", value: 1 },
      { key: "日中（24h以内）受診指示", value: 2 },
      { key: "日中（24h以降）受診指示", value: 3 },
      { key: "救急受診指示", value: 4 },
      { key: "その他", value: 5 }
    ]
  })
};
