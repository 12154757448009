<template>
  <v-row class="parent-special-note">
    <v-col md="12" class="pb-2">
      <v-card>
        <v-toolbar color="#4D4D4D" height="32">
          <v-toolbar-title
            >ユーザの特記事項（※入力時は年月日を記載）</v-toolbar-title
          >
        </v-toolbar>
        <v-textarea
          v-if="active != null"
          v-model="user.notice"
          name="user-notice"
          hide-details
          :readonly="!active"
        />
        <v-textarea
          v-else
          v-model="user.notice"
          readonly
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ParentsSpecialNote",
  props: {
    active: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style>
/* readonly 時の背景色を無透過の#FFFへ上書きするスタイル */
.parent-special-note .v-input--readonly.v-text-field .v-field__overlay {
  background-color: #fff !important;
  opacity: 1 !important;
}
</style>

<style lang="scss" scoped>
/* override */
.v-toolbar-title {
  color: #FFF;
  font-size: 12px;
  font-weight: bold;
}
</style>
