<template>
  <v-container fluid style="background-color: #fdeef3" class="karte-view">
    <v-row class="ma-2 bg-white">
      <v-col>
        <v-container fluid class="pt-0 pb-0">
          <v-row class="pa-0">
            <v-col sm="12" md="12" class="pb-0">
              <span style="font-size: 1.17em; font-weight: bold;">{{ reservation.date }} {{ reservation.time }}</span>
            </v-col>
          </v-row>
          <v-row class="pl-4 pr-4">
            <v-col sm="12" md="12" class="pa-0 py-2">
              <p class="mb-0 body-2" style="font-size: 14px">事前問診</p>
            </v-col>
          </v-row>
          <v-row class="pl-4 pr-4">
            <v-col class="pa-0">
              <v-container fluid class="pa-2">
                <v-row class="pa-0" style="border: solid 1px #bdbdbd">
                  <v-col
                    md="6"
                    class="pa-0"
                    style="border-right: solid 1px #bdbdbd"
                  >
                    <v-card :flat="true" ripple>
                      <v-card-text>
                        <span v-text="reservation.doctor" />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col md="6" class="pa-0">
                    <v-card :flat="true" ripple>
                      <v-card-text>
                        <span v-text="reservation.tool" />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row
                  style="
                    border: solid 1px #bdbdbd;
                    max-height: 300px;
                    overflow-y: scroll;
                  "
                >
                  <v-col sm="12" md="12" class="pa-0">
                    <v-card :flat="true" ripple>
                      <v-card-text>
                        <div v-if="reservation.department_type == 1">
                          <span>
                            <b>子どもID</b>
                            <span class="pr-3"
                              >： {{ reservation.childId }}</span
                            >
                          </span>
                          <span>
                            <b>ユーザID</b>
                            <span class="pr-3"
                              >： {{ reservation.userId }}</span
                            >
                          </span>
                          <span>
                            <b>クーポン</b>
                            <span class="pr-3"
                              >： {{ reservation.coupon }}</span
                            >
                          </span>
                          <br />
                          <span v-if="isNameDisplayable">
                            <span
                              ><b>ユーザ</b>： {{ reservation.userName }}</span
                            >
                            <br />
                          </span>
                          <child-full-property
                            :object="reservation"
                            :is-child-name-displayable="isNameDisplayable"
                          /><br />
                          <span>
                            <v-btn
                              v-if="reservation.firstAid"
                              size="small"
                              color="white"
                              >救急</v-btn
                            >
                            <v-btn size="small" color="grey" theme="dark">{{
                              reservation.category
                            }}</v-btn>
                          </span>
                        </div>
                        <div v-if="reservation.department_type >= 2">
                          <span>
                            <b>ユーザID</b>
                            <span class="pr-3"
                              >： {{ reservation.userId }}</span
                            >
                          </span>
                          <span
                            ><b>クーポン</b>： {{ reservation.coupon }}</span
                          >
                          <br />
                          <span v-if="isNameDisplayable">
                            <span
                              ><b>ユーザ</b>： {{ reservation.userName }}</span
                            >
                            <br />
                          </span>
                          <span
                            ><b>相談対象者の生年月日</b>：
                            {{ reservation.userBirthdate }}</span
                          ><br />
                          <span>
                            <v-btn
                              v-if="reservation.firstAid"
                              size="small"
                              color="white"
                              >救急</v-btn
                            >
                            <v-btn size="small" color="grey" theme="dark">{{
                              reservation.category
                            }}</v-btn> </span
                          ><br />
                          <span>
                            <b>過去の妊娠回数</b>:
                            {{ reservation.pastPregnancyNum }}
                            <span class="px-3">|</span>
                            <b>過去の出産回数</b>:
                            {{ reservation.pastDeliveryNum }}
                            <span class="px-3">|</span>
                            <b>出産予定日・最後の出産日</b>:
                            {{ reservation.lastDueDate }} </span
                          ><br />
                          <span v-if="reservation.inPregnancy">
                            <b>妊娠方法</b>: {{ reservation.pregnantWayType }}
                            <span class="px-3">|</span>
                            <b>帝王切開の予定</b>:
                            {{ reservation.toBeCsection }} </span
                          ><br v-if="reservation.inPregnancy" />
                          <span v-if="!reservation.inPregnancy">
                            <b>出産方法</b>: {{ reservation.deliveryWayType }}
                            <span class="px-3">|</span>
                            <b>授乳状況</b>: {{ reservation.breastFeedingType }}
                          </span>
                        </div>
                        <div>
                          <span> <b>提供サービス</b>: </span>
                          <div>
                            <v-chip
                              class="ma-1"
                              label
                              :color="
                                reservation.useReservation
                                  ? 'red'
                                  : 'default'
                              "
                            >
                              <span class="text-black">夜間</span>
                              <v-icon
                                v-if="reservation.useReservation"
                                color="rgba(0, 0, 0, .54)"
                                size="small"
                                end
                              >
                                mdi-circle-outline
                              </v-icon>
                              <v-icon
                                v-if="!reservation.useReservation"
                                size="small"
                                end
                              >
                                close
                              </v-icon>
                            </v-chip>
                            <v-chip
                              class="ma-1"
                              label
                              :color="
                                reservation.useAnytimeConsultation
                                  ? 'red'
                                  : 'default'
                              "
                            >
                              <span class="text-black">いつでも</span>
                              <v-icon
                                v-if="reservation.useAnytimeConsultation"
                                color="rgba(0, 0, 0, .54)"
                                size="small"
                                end
                              >
                                mdi-circle-outline
                              </v-icon>
                              <v-icon
                                v-if="!reservation.useAnytimeConsultation"
                                size="small"
                                end
                              >
                                close
                              </v-icon>
                            </v-chip>
                            <v-chip
                              class="ma-1"
                              label
                              :color="
                                reservation.useDaytimeAdvice
                                  ? 'red'
                                  : 'default'
                              "
                            >
                              <span class="text-black">日中助産師</span>
                              <v-icon
                                v-if="reservation.useDaytimeAdvice"
                                color="rgba(0, 0, 0, .54)"
                                size="small"
                                end
                              >
                                mdi-circle-outline
                              </v-icon>
                              <v-icon
                                v-if="!reservation.useDaytimeAdvice"
                                size="small"
                                end
                              >
                                close
                              </v-icon>
                            </v-chip>
                          </div>
                        </div>
                      </v-card-text>
                      <v-card-text>
                        <span v-text="reservation.description" />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
        <div class="child-special-note pa-3 mt-4">
          <v-card>
            <!-- 子の特記事項（子の情報がある場合に表示）-->
            <v-toolbar color="#4D4D4D" height="32">
              <v-toolbar-title
                >子の特記事項（※入力時は年月日を記載）</v-toolbar-title
              >
            </v-toolbar>
            <v-textarea
              v-model="child.notice"
              name="child-notice"
              hide-details
              readonly
            />
          </v-card>
        </div>
        <div class="pl-3 mt-4">
          <karte-body />
        </div>
        <div class="pl-3 pt-2">
          <chat-view :chat-id="parseInt($route.params.id)" log-type="karte" />
        </div>
        <div class="pl-3">
          <karte-operation-fields />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import KarteBody from "./shared/KarteBody.vue";
import ChildFullProperty from "./shared/ChildFullProperty.vue";
import ChatView from "./shared/ChatView.vue";
import KarteOperationFields from "./shared/KarteOperationFields.vue";

export default {
  name: "KarteView",
  components: {
    KarteBody,
    ChildFullProperty,
    ChatView,
    KarteOperationFields,
  },
  data: () => ({
    editing: false,
    isNameDisplayable: false,
  }),
  watch: {
    $route() {
      this.$store.dispatch("findReservation", {
        reservationId: this.$route.params.id,
      });
      this.$store.dispatch("findKarte", {
        reservationId: this.$route.params.id,
      });
      this.$store.dispatch("findChild", {
        reservationId: this.$route.params.id,
      });
    },
  },
  computed: {
    ...mapGetters(["reservation", "karte", "child"]),
  },
  created() {
    this.$store.dispatch("findReservation", {
      reservationId: this.$route.params.id,
    });
    this.$store.dispatch("findKarte", {
      reservationId: this.$route.params.id,
    });
    this.$store.dispatch("findChild", {
      reservationId: this.$route.params.id,
    });

    if (window.opener.location.pathname === "/reservations/karte") {
      this.isNameDisplayable = true;
    }
  },
};
</script>
<style lang="scss">
/* override */
.karte-view .v-toolbar-title {
  color: #fff;
  font-size: 13px;
  font-weight: bold;
}
.karte-view div.v-input--disabled.v-text-field .v-text-field__slot textarea {
  color: #212121 !important;
}
/* readonly 時の background-color を標準のグレーではなく #212121 へ上書きするスタイル */
.child-special-note .v-input--readonly.v-text-field .v-field__overlay {
  background-color: #fff !important;
}
</style>
