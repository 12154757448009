<template>
  <p class="mb-1">
    対応者：{{ departmentName }}
  </p>
</template>

<script>
export default {
  name: "KarteDepartmentName",
  props: {
    departmentType: {
      type: Number,
      required: true
    }
  },
  computed: {
    departmentName: function () {
      switch(this.departmentType) {
      case 1:
        return "小児科医";
      case 2:
        return "産婦人科医";
      case 3:
        return "助産師";
      default:
        return "";
      }
    }
  }
};
</script>
