import childApi from "../../api/children";
import * as mutationTypes from "../mutation-types";


const state = {
  child: {}
};


const getters = {
  child: state => state.child
};


const actions = {
  findChild({ commit }, params) {
    return new Promise((resolve) => {
      childApi.findChild(child => {
        commit(mutationTypes.ASSIGN_CHILD, { child });
        resolve();
      }, params);
    });
  },

  updateChild({ commit }, params) {
    return new Promise((resolve) => {
      childApi.updateChild(
        params,
        () => {
          resolve();
        },
        () => {
          // 必要に応じてエラー処理を追加
          resolve();
        });
    });
  },

  resetChild({ commit }) {
    let child = {};
    commit(mutationTypes.ASSIGN_CHILD, { child });
  },
};


const mutations = {
  [mutationTypes.ASSIGN_CHILD](state, { child }) {
    state.child = child;
  }
};


export default {
  state,
  getters,
  actions,
  mutations
};
