import axios from "./index";


export default {
  findRoom(callback, params) {
    axios.get("/api/v1/rooms/" + params.roomId).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },
};
