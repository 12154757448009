export default {
  methods: {
    labelColor(status){
      switch (status) {
      case 0:
        return "red";
      case 1:
        return "orange";
      case 2:
        return "green";
      case 3:
        return "grey-lighten-2";
      case 4:
        return "grey-darken-4";
      case 5:
        return "blue-grey-darken-3";

      }
    }
  }
};
