<template>
  <div>
    {{ doctor.name }}
  </div>
</template>

<script>
export default {
  name: "AnytimeConsultationShiftValue",
  props: {
    doctor: {
      type: Object,
      required: true
    }
  },
};
</script>
