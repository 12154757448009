import doctorApi from "../../api/doctors";
import * as mutationTypes from "../mutation-types";


const state = {
  doctors: [],
  doctorsByDepartmentType: null
};


const getters = {
  doctors: state => state.doctors,
  doctorsByDepartmentType: state => departmnet_type => state.doctorsByDepartmentType[departmnet_type]
};


const actions = {
  fetchAllDoctors({ commit }, params) {
    return new Promise((resolve) => {
      doctorApi.fetchAllDoctors(doctors => {
        commit(mutationTypes.ASSIGN_DOCTORS, { doctors });
        resolve();
      }, params);
    });
  },

  fetchDoctorsByDepartmentType({ commit }, params) {
    return new Promise((resolve) => {
      doctorApi.fetchDoctorsByDepartmentType(doctors => {
        commit(mutationTypes.ASSIGN_DOCTORS_BY_DEPARTMENT_TYPE, { doctors });
        resolve();
      }, params);
    });
  }
};


const mutations = {
  [mutationTypes.ASSIGN_DOCTORS](state, { doctors }) {
    state.doctors = doctors;
  },
  [mutationTypes.ASSIGN_DOCTORS_BY_DEPARTMENT_TYPE](state, { doctors }) {
    state.doctorsByDepartmentType = doctors;
  }
};


export default {
  state,
  getters,
  actions,
  mutations
};
