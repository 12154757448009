<template>
  <v-container class="pa-0 anytime-consultation-addon">
    <!-- 運営からの特記事項 -->
    <operator-special-note :active="active" />
    <!-- ユーザの特記事項 -->
    <parents-special-note :active="active" />
    <!-- 子の特記事項 -->
    <child-special-note
      v-if="anytimeConsultation.department_type === 'pediatrician'"
      :active="active"
    />
    <!-- エジンバラ産後うつ質問票 -->
    <epds-list v-if="user.id != null" :user-id="user.id" />
    <!--  いつでも相談カルテ一覧  -->
    <v-row>
      <v-col
        md="12"
        class="pb-2"
      >
        <v-card>
          <v-toolbar
            color="#4D4D4D"
            height="32"
          >
            <v-toolbar-title>いつでも相談カルテ一覧</v-toolbar-title>
          </v-toolbar>
          <v-card
            v-if="anytimeConsultationKartes.length != 0"
            class="overflow-y-auto"
            max-height="400"
          >
            <v-card-item class="pa-0">
              <v-card
                v-for="consultation in anytimeConsultationKartes"
                :key="consultation.id"
              >
                <v-card-text>
                  <div>
                    <p class="mb-1">
                      {{ consultation.title }}
                    </p>
                    <p class="mb-1 d-flex">
                      {{ consultation.simpleAskedAt }} （{{
                        consultation.doctorName
                      }}）
                    </p>
                    <p class="mb-1">回答状況：{{ consultation.status }}</p>
                    <p class="mb-1">
                      ICD10暫定診断：{{ consultation.icd10BranchName }}
                    </p>
                  </div>
                </v-card-text>
                <v-card-actions class="pt-0">
                  <v-spacer />
                  <a @click="openAnytimeConsultationView(consultation.id)">
                    <v-btn
                      color="black"
                      style="background-color: #EC407A;"
                    >
                      閲覧
                      <v-icon icon="mdi-eye" />
                    </v-btn>
                  </a>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-card-item>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <!--  関連するいつでもカルテ一覧（他科・兄弟など）  -->
    <anytime-consultation-karte-list
      :title="'関連するいつでもカルテ一覧(他科・兄弟など)'"
      :anytime-consultations="anytimeConsultationSiblingKartes"
      :is-child-name-displayable="false"
    />
    <!--  夜間相談カルテ一覧  -->
    <karte-list
      :title="'夜間相談カルテ一覧'"
      :karte-list="userKartes"
      :is-child-name-displayable="false"
    />
    <!--  日中助産師カルテ一覧  -->
    <daytime-advice-sheet-addon-list />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import AnytimeConsultationKarteList from "./addon/AnytimeConsultationKarteList.vue";
import EpdsList from "./addon/EpdsList.vue";
import ParentsSpecialNote from "./addon/ParentsSpecialNote.vue";
import ChildSpecialNote from "./addon/ChildSpecialNote.vue";
import KarteList from "./addon/SiblingKarteList.vue";
import DaytimeAdviceSheetAddonList from "./addon/DaytimeAdviceSheetAddonList.vue";
import OperatorSpecialNote from "./addon/OperatorSpecialNote.vue";

export default {
  name: "AnytimeConsultationAddon",
  components: {
    AnytimeConsultationKarteList,
    EpdsList,
    ParentsSpecialNote,
    ChildSpecialNote,
    KarteList,
    DaytimeAdviceSheetAddonList,
    OperatorSpecialNote,
  },
  props: {
    active: Boolean,
    userId: {
      type: Number,
      required: true,
    },
    childId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data: () => ({
    cancelToken: axios.CancelToken,
    cancelSource: null,
  }),
  computed: {
    ...mapGetters([
      "user",
      "child",
      "anytimeConsultationKartes",
      "anytimeConsultation",
      "anytimeConsultationSiblingKartes",
      "userKartes",
      "userDaytimeAdviceSheets",
    ]),
  },
  created() {
    this.fetchData();
  },
  unmounted() {
    this.clearData();
  },
  methods: {
    ...mapActions(["resetUser", "resetChild"]),
    fetchData() {
      if (this.cancelSource) {
        this.cancelSource.cancel();
      }
      this.cancelSource = this.cancelToken.source();

      if (this.userId != null) {
        this.$store.dispatch("findUser", {
          userId: this.userId,
        });

        this.$store.dispatch("fetchUserKartes", {
          userId: this.userId,
          cancelToken: this.cancelSource.token,
        });

        this.$store.dispatch("fetchUserDaytimeAdviceSheets", {
          userId: this.userId,
          cancelToken: this.cancelSource.token,
        });
      }

      if (this.childId != null) {
        this.$store.dispatch("findChild", {
          childId: this.childId,
        });
      }

      this.$store.dispatch("fetchAnytimeConsultationKartes", {
        id: this.$route.params.id,
        cancelToken: this.cancelSource.token,
      });

      this.$store.dispatch("fetchAnytimeConsultationSiblingKartes", {
        id: this.$route.params.id,
        cancelToken: this.cancelSource.token,
      });
    },
    clearData() {
      this.resetUser();
      this.resetChild();
      this.$store.dispatch("clearUserKartes");
      this.$store.dispatch("clearUserDaytimeAdviceSheets");
      this.$store.dispatch("clearUserDaytimeAdviceSheets");
      this.$store.dispatch("clearAnytimeConsultationKartes");
      this.$store.dispatch("clearAnytimeConsultationSiblingKartes");
    },
    openAnytimeConsultationView: (id) => {
      window.open(
        "/anytime_consultations/view/" + id,
        "",
        "width=480, height=640"
      );
    },
    openKarte: (id) => {
      window.open("/reservations/" + id, "", "width=480, height=640");
    },
  },
};
</script>

<style lang="scss" scoped>
/* override */
.v-toolbar-title {
  color: #FFF;
  font-size: 13px;
  font-weight: bold;
}
</style>
