<template>
  <v-container class="anytime-consultation-inquiry">
    <div>
      <span style="font-size: 1.5em; font-weight: bold;">
        {{ consultation.title }}事前問診 (ID: {{ consultation.id }})
      </span>
    </div>
    <div :class="{'mt-4':true, 'inquery-grid-container': true}">
      <v-card
        class="pa-4 inquery-grid-user"
        variant="outlined"
        tile
      >
        <anytime-consultation-user-info :consultation="consultation" />
      </v-card>
      <v-card
        class="pa-4 inquery-grid-contract"
        variant="outlined"
        tile
      >
        <div>
          <b>相談方法別の利用可否</b>:
        </div>
        <div class="d-flex flex-wrap">
          <v-chip
            class="ma-1"
            label
            :color="consultation.useReservation ? 'red' : 'default'"
          >
            <span class="text-black">夜間</span>
            <v-icon
              v-if="consultation.useReservation"
              color="rgba(0, 0, 0, .54)"
              size="small"
              end
            >
              mdi-circle-outline
            </v-icon>
            <v-icon
              v-if="!consultation.useReservation"
              size="small"
              end
            >
              mdi-close
            </v-icon>
          </v-chip>
          <v-chip
            class="ma-1"
            label
            :color="consultation.useAnytimeConsultation ? 'red' : 'default'"
          >
            <span class="text-black">いつでも</span>
            <v-icon
              v-if="consultation.useAnytimeConsultation"
              color="rgba(0, 0, 0, .54)"
              size="small"
              end
            >
              mdi-circle-outline
            </v-icon>
            <v-icon
              v-if="!consultation.useAnytimeConsultation"
              size="small"
              end
            >
              mdi-close
            </v-icon>
          </v-chip>
          <v-chip
            class="ma-1"
            label
            :color="consultation.useDaytimeAdvice ? 'red' : 'default'"
          >
            <span class="text-black">日中助産師</span>
            <v-icon
              v-if="consultation.useDaytimeAdvice"
              color="rgba(0, 0, 0, .54)"
              size="small"
              end
            >
              mdi-circle-outline
            </v-icon>
            <v-icon
              v-if="!consultation.useDaytimeAdvice"
              size="small"
              end
            >
              mdi-close
            </v-icon>
          </v-chip>
        </div>

        <div>
          <b>相談員種別の利用可否</b>:
        </div>
        <div class="d-flex flex-wrap">
          <v-chip
            class="ma-1"
            label
            :color="
              consultation.pediatricianEnabled
                ? 'red'
                : 'default'
            "
          >
            <span class="text-black">小児科医</span>
            <v-icon
              v-if="consultation.pediatricianEnabled"
              color="rgba(0, 0, 0, .54)"
              size="small"
              end
            >
              mdi-circle-outline
            </v-icon>
            <v-icon
              v-if="!consultation.pediatricianEnabled"
              size="small"
              end
            >
              mdi-close
            </v-icon>
          </v-chip>
          <v-chip
            class="ma-1"
            label
            :color="
              consultation.obstetricsEnabled
                ? 'red'
                : 'default'
            "
          >
            <span class="text-black">産婦人科医</span>
            <v-icon
              v-if="consultation.obstetricsEnabled"
              color="rgba(0, 0, 0, .54)"
              size="small"
              end
            >
              mdi-circle-outline
            </v-icon>
            <v-icon
              v-if="!consultation.obstetricsEnabled"
              size="small"
              end
            >
              mdi-close
            </v-icon>
          </v-chip>
          <v-chip
            class="ma-1"
            label
            :color="
              consultation.midwifeEnabled
                ? 'red'
                : 'default'
            "
          >
            <span class="text-black">助産師</span>
            <v-icon
              v-if="consultation.midwifeEnabled"
              color="rgba(0, 0, 0, .54)"
              size="small"
              end
            >
              mdi-circle-outline
            </v-icon>
            <v-icon
              v-if="!consultation.midwifeEnabled"
              size="small"
              end
            >
              mdi-close
            </v-icon>
          </v-chip>
        </div>
      </v-card>
      <v-card
        class="pa-4 inquery-grid-detail"
        variant="outlined"
        tile
      >
        <div
          class="mb-2"
        >
          <b>質問日時</b>： {{ consultation.askedAt }}
        </div>

        <div v-if="consultation.diseaseStartedAt">
          <b>いつから症状が出ているか</b>： {{ consultation.diseaseStartedAt }}
        </div>

        <div
          v-for="(value, key) in consultation.consultationDetails"
          :key="key"
        >
          <b>{{ key }}</b>: {{ value }}
        </div>

        <div class="mt-2">
          <span><b>医師への追加伝達事項</b>： </span><br>
          <div
            class="highlight-text"
            v-html="hightlightConvertedAdditionalDetails"
          />
        </div>

        <div
          class="mt-2"
        >
          <span><b>添付画像</b>： </span>
          <span v-if="!consultation.image">
            なし
          </span>
          <div v-else>
            <img
              :src="consultation.image"
              style="max-width: 90%; height: 48px; cursor: pointer;"
              oncontextmenu="return false;"
              onselectstart="return false;"
              onmousedown="return false;"
              @click="showMedia"
            >
          </div>
        </div>

        <br>
        <div v-if="consultation.youthConsultation">
          <div class="strong notice py-4">
            ※ この相談は生徒本人による相談です ※
          </div>
        </div>
      </v-card>
    </div>
    <!-- 画像拡大用 -->
    <v-container class="ma-0 pa-0">
      <v-row justify="center">
        <v-col class="ma-0 pa-0">
          <v-dialog
            v-model="imageDialog"
            max-width="60%"
            scrollable
          >
            <v-card class="my-5">
              <img
                v-if="imageUrl !== undefined"
                :src="imageUrl"
                class="secure-image"
                style="display: block; width: 100%; max-height: 100%"
              >
              <v-btn
                position="absolute"
                class="icon-button"
                color="gray lighten-4"
                fab
                size="x-small"
                @click="closeDialog()"
              >
                ×
              </v-btn>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <!--// 画像拡大用 -->
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AnytimeConsultationUserInfo from "./AnytimeConsultationUserInfo.vue";

function convertedHighlightText(original, keywords) {
  if (typeof original !== "string" || original.length == 0) {
    return "";
  }
  if (Array.isArray(keywords) && keywords.length >= 1) {
    keywords.forEach((str) => {
      original = original.replaceAll(str, "<em>" + str + "</em>");
    });
  }
  original = original.replaceAll(/\r\n/g, "<br>").replace(/\r/g, "<br>").replace(/\n/g, "<br>");
  return original;
}

export default {
  name: "AnytimeConsultationInquiry",
  components: {
    AnytimeConsultationUserInfo
  },
  props: {
    keywords: {
      type: Array,
      default: () => {[];}
    },
  },
  data: () => ({
    imageUrl: "",
    imageDialog: false,
  }),
  computed: {
    ...mapGetters({
      answer: "anytimeConsultationAnswer",
      consultation: "anytimeConsultation",
    }),
    hightlightConvertedAdditionalDetails: function () {
      return convertedHighlightText(this.$sanitize(this.consultation.additionalDetails), this.keywords);
    }
  },
  methods: {
    showMedia: function(e) {
      this.$data.imageDialog = true;
      this.$data.imageUrl = e.currentTarget.getAttribute("src");
    },
    closeDialog: function(e) {
      this.$data.imageDialog = false;
    },
  }
};
</script>

<style lang="scss">
.inquery-grid-container{
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-areas  : "user detail"
                         "contract  detail";

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
  .is-minimum & {
    display: flex;
    flex-direction: column;
    .inquery-grid-contract {
      display: none;
    }
  }
  &.is-clinic-doctor{
    display: flex;
    flex-direction: column;
  }
}

.inquery-grid-user{
  grid-area: user;
}
.inquery-grid-contract{
  grid-area: contract;
}
.inquery-grid-detail{
  grid-area: detail;
}

.anytime-consultation-inquiry {
  .highlight-text {
    em {
      background-color: rgba(255, 255, 0, 0.4);
      font-style: normal;
      font-weight: bold;
    }
  }
}

.icon-button {
  top: 0;
  right: 0;
  font-size: 18px;
}
.v-theme--light.v-card.v-card--variant-outlined {
  border: 1px solid rgba(0, 0, 0, .12);
}

</style>
