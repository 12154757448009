import axios from "./index";


export default {
  fetchReservations(callback, params) {
    axios.get("/api/v1/reservations", {
      params: {
        date: params.date
      }
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  fetchFirstReservationsOfDay(callback, params) {
    axios.get("/api/v1/reservations/first", {
      params: {
        date: params.date
      }
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  fetchReservationsByRoom(callback, params) {
    axios.get("/api/v1/reservations/room", {
      params: {
        date: params.date,
        room: params.room
      }
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  findReservation(callback, params) {
    axios.get("/api/v1/reservations/" + params.reservationId).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  }
};
