import anytimeConsultationShiftApi from "../../api/anytime_consultation_shifts";
import * as mutationTypes from "../mutation-types";

const state = {
  anytimeConsultationShiftUpdateErrorDetail: "",
  onShift: false,
  noticeExpiration: false,
};

const getters = {
  anytimeConsultationShiftUpdateErrorDetail : state => state.anytimeConsultationShiftUpdateErrorDetail,
  onShift: state => state.onShift,
  noticeExpiration: state => state.noticeExpiration,
};

const actions = {
  createAnytimeConsultationShift({ commit }, params) {
    return new Promise((resolve, reject) => {
      anytimeConsultationShiftApi.create(
        params,
        () => {
          let shiftUpdateErrorDetail = "";
          commit(mutationTypes.AC_SHIFT_UPDATE_ERROR_DETAIL, { shiftUpdateErrorDetail });
          resolve();
        },
        (error) => {
          let shiftUpdateErrorDetail = error.response.data["message"];
          commit(mutationTypes.AC_SHIFT_UPDATE_ERROR_DETAIL, { shiftUpdateErrorDetail });
          reject();
        });
    });
  },

  updateAnytimeConsultationShift({ commit }, params) {
    return new Promise((resolve, reject) => {
      anytimeConsultationShiftApi.update(
        params,
        () => {
          let shiftUpdateErrorDetail = "";
          commit(mutationTypes.AC_SHIFT_UPDATE_ERROR_DETAIL, { shiftUpdateErrorDetail });
          resolve();
        },
        (error) => {
          let shiftUpdateErrorDetail = error.response.data["message"];
          commit(mutationTypes.AC_SHIFT_UPDATE_ERROR_DETAIL, { shiftUpdateErrorDetail });
          reject();
        });
    });
  },

  deleteAnytimeConsultationShift({ commit }, params) {
    return new Promise((resolve, reject) => {
      anytimeConsultationShiftApi.delete(
        params,
        () => {
          let shiftUpdateErrorDetail = "";
          commit(mutationTypes.AC_SHIFT_UPDATE_ERROR_DETAIL, { shiftUpdateErrorDetail });
          resolve();
        },
        (error) => {
          let shiftUpdateErrorDetail = error.response.data["message"];
          commit(mutationTypes.AC_SHIFT_UPDATE_ERROR_DETAIL, { shiftUpdateErrorDetail });
          reject();
        });
    });
  },

  isOnAnytimeConsultationShift({ commit }, params) {
    return new Promise((resolve) => {
      anytimeConsultationShiftApi.isOnShift(onShift => {
        commit(mutationTypes.ASSIGN_IS_ON_ANYTIME_CONSULTATION_SHIFT, { onShift });
        resolve();
      }, params);
    });
  },

  isAnswerNoticeExpiration({ commit }, params) {
    return new Promise((resolve) => {
      anytimeConsultationShiftApi.isNoticeExpiration(noticeExpiration => {
        commit(mutationTypes.ASSIGN_IS_NOTICE_EXPIRATION, { noticeExpiration });
        resolve();
      }, params);
    });
  },
};

const mutations = {
  [mutationTypes.AC_SHIFT_UPDATE_ERROR_DETAIL](state, { shiftUpdateErrorDetail }) {
    state.anytimeConsultationShiftUpdateErrorDetail = shiftUpdateErrorDetail;
  },
  [mutationTypes.ASSIGN_IS_ON_ANYTIME_CONSULTATION_SHIFT](state, { onShift }) {
    state.onShift =  onShift;
  },
  [mutationTypes.ASSIGN_IS_NOTICE_EXPIRATION](state, { noticeExpiration }) {
    state.noticeExpiration =  noticeExpiration;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
