import axios from "./index";


export default {
  fetchAllDoctors(callback) {
    axios.get("/api/v1/doctors", {}).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },
  fetchDoctorsByDepartmentType(callback) {
    axios.get("/api/v1/doctors/by_department_type", {}).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  }
};
