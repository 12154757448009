import axios from "./index";

export default {
  findUser(callback, params) {
    axios.get("/api/v1/users", {
      params: {
        user_id: params.userId
      }
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  updateUser(params, successCallback, failureCallback) {
    axios.put("/api/v1/users/" + params.id, {
      user: {
        notice: params.notice,
        operator_notice: params.operatorNotice
      }
    }, {
      headers: { "Content-Type": "application/json" }
    }
    ).then(() => {
      successCallback();
    }).catch(error => {
      console.log(error);
      failureCallback(error);
    });
  }
};
