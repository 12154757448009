import axios from "./index";

export default {
  findChild(callback, params) {
    axios.get("/api/v1/children", {
      params: {
        child_id: params.childId
      },
      validateStatus: function(status) { return status < 500; },
    }).then(response => {
      if (response.status === 404) {
        callback({id: null, notice: null});
      } else {
        callback(response.data);
      }
    }).catch(error => {
      console.log(error);
    });
  },

  updateChild(params, successCallback, failureCallback) {
    axios.put("/api/v1/children/" + params.id, {
      child: {
        notice: params.notice
      }
    }, {
      headers: { "Content-Type": "application/json" }
    }
    ).then(() => {
      successCallback();
    }).catch(error => {
      console.log(error);
      failureCallback(error);
    });
  }
};
