/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import { createApp } from "vue";

import router from "../vue/routes";

import store from "../vue/stores";
import vuetify from "../vue/plugins/vuetify";

import ActionCable from "actioncable";

import { createPinia } from "pinia";
import sanitizeHTML from "sanitize-html";
import linkify from "vue-3-linkify";

import App from "../vue/App.vue";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const cable = ActionCable.createConsumer();

document.addEventListener("DOMContentLoaded", () => {
  const app = createApp(App);
  app.use(router);
  app.use(store);
  app.use(vuetify);
  app.use(linkify);
  app.use(createPinia());
  app.config.globalProperties.$sanitize = sanitizeHTML;
  app.component("VueDatePicker", VueDatePicker);

  app.config.globalProperties.$cable = cable;
  app.provide("cable", cable);

  document.body.appendChild(document.createElement("app"));
  app.mount("app");
});
