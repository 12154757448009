<template>
  <v-card>
    <v-list
      class="pt-0 pb-0 period-list"
    >
      <div
        v-for="period in roomReservationList"
        :key="period.name"
      >
        <period :period="period" />
      </div>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Period from "./Period.vue";

export default {
  name: "PeriodList",
  components: {
    Period
  },
  computed: {
    ...mapGetters([
      "roomReservationList",
      "periodListTimeoutId"
    ]),
  },
  watch: {
    "$route" () {
      this.startAutoReload();
    }
  },
  created() {
    this.startAutoReload();
  },
  unmounted(){
    // 自動更新を停止する
    clearInterval(this.periodListTimeoutId);
  },
  methods: {
    fetchReservationsByRoom: function(){
      this.$store.dispatch("fetchReservationsByRoom", {
        date: this.$route.query.date,
        room: this.$route.query.room,
      });
    },
    startAutoReload: function() {
      // 以前設定されたtimeoutをクリア
      clearInterval(this.periodListTimeoutId);
      // 最初の1回の実行
      this.fetchReservationsByRoom();
      // 30秒
      var timeoutId = setInterval(this.fetchReservationsByRoom, 30 * 1000);
      this.$store.dispatch("assignPeriodListTimeoutId", timeoutId);
    }
  }
};
</script>
