import reservationApi from "../../api/reservations";
import * as mutationTypes from "../mutation-types";


const state = {
  reservationList: [],
  firstReservationList: [],
  roomReservationList: [],
  reservation: {},
  reservationTimeoutId: null,
  periodListTimeoutId: null
};


const getters = {
  reservationList: state => state.reservationList,
  firstReservationList: state => state.firstReservationList,
  roomReservationList: state => state.roomReservationList,
  reservation: state => state.reservation,
  reservationTimeoutId:  state => state.reservationTimeoutId,
  periodListTimeoutId:  state => state.periodListTimeoutId,
};


const actions = {
  fetchReservations({ commit }, params) {
    return new Promise((resolve) => {
      reservationApi.fetchReservations(reservationList => {
        commit(mutationTypes.ASSIGN_RESERVATIONS, { reservationList });
        resolve();
      }, params);
    });
  },

  fetchFirstReservationsOfDay({ commit }, params) {
    return new Promise((resolve) => {
      reservationApi.fetchFirstReservationsOfDay(firstReservationList => {
        commit(mutationTypes.ASSIGN_FIRST_RESERVATIONS_OF_DAY, { firstReservationList });
        resolve();
      }, params);
    });
  },

  fetchReservationsByRoom({ commit }, params) {
    return new Promise((resolve) => {
      reservationApi.fetchReservationsByRoom(roomReservationList => {
        commit(mutationTypes.ASSIGN_RESERVATIONS_BY_ROOM, { roomReservationList });
        resolve();
      }, params);
    });
  },

  findReservation({ commit }, query) {
    return new Promise((resolve) => {
      reservationApi.findReservation(reservation => {
        commit(mutationTypes.ASSIGN_RESERVATION, { reservation });
        resolve();
      }, query);
    });
  },

  assignReservationTimeoutId({ commit }, timeoutId ){
    commit(mutationTypes.ASSIGN_RESERVATION_TIMEOUT_ID, { timeoutId });
  },

  assignPeriodListTimeoutId({ commit }, timeoutId ){
    commit(mutationTypes.ASSIGN_PERIOD_LIST_TIMEOUT_ID, { timeoutId });
  },

  clearReservation({ commit }) {
    let reservation = { date: "データ取得中", doctor: "", loading: true };
    commit(mutationTypes.ASSIGN_RESERVATION, { reservation });
  }
};


const mutations = {
  [mutationTypes.ASSIGN_RESERVATIONS](state, { reservationList }) {
    state.reservationList = reservationList;
  },

  [mutationTypes.ASSIGN_FIRST_RESERVATIONS_OF_DAY](state, { firstReservationList }) {
    state.firstReservationList = firstReservationList;
  },

  [mutationTypes.ASSIGN_RESERVATIONS_BY_ROOM](state, { roomReservationList }) {
    state.roomReservationList = roomReservationList;
  },

  [mutationTypes.ASSIGN_RESERVATION](state, { reservation }) {
    state.reservation = reservation;
  },

  [mutationTypes.ASSIGN_RESERVATION_TIMEOUT_ID](state, { timeoutId }) {
    state.reservationTimeoutId = timeoutId;
  },

  [mutationTypes.ASSIGN_PERIOD_LIST_TIMEOUT_ID](state, { timeoutId }) {
    state.periodListTimeoutId = timeoutId;
  }
};


export default {
  state,
  getters,
  actions,
  mutations
};
