import axios from "./index";

export default {
  loginByGoogleIdToken(callback, params) {
    axios.post("/api/v1/google_login/callback", {
      id_token: params.id_token,
    }).then(() =>{
      callback(true);
    }).catch(error => {
      console.log(error);
      callback(false);
    });
  },
};
