import axios from "./index";


export default {
  fetchShiftsOfDay(callback, params) {
    axios.get("/api/v1/shifts", {
      params: {
        date: params.date
      }
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  updateShifts(params, successCallback, failureCallback) {
    axios.put("/api/v1/shifts/bulk", {
      shifts: params
    }, {
      headers: { "Content-Type": "application/json" }
    }
    ).then(response => {
      successCallback(response.data);
    }).catch(error => {
      console.log(error);
      failureCallback(error);
    });
  }
};
