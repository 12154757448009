<template>
  <div>
    <top-menu
      ref="top-menu"
      @logout="$emit('logout')"
    />
    <v-tabs
      align-tabs="center"
      fixed-tabs
      show-arrows
      slider-color="pink"
      color="grey-darken-4"
      class="top-tab v-theme--light v-tabs-bar"
    >
      <v-tab
        :to="{ name: 'daytimeAdviceRoom' }"
        class="my-0 me-0"
        value="0"
      >
        担当
      </v-tab>
      <v-tab
        v-if="isOperator()"
        :to="{ name: 'daytimeAdviceMonitor' }"
        class="ma-0"
        value="1"
      >
        モニタリング
      </v-tab>
      <v-tab
        :to="{ name: 'daytimeAdviceSearch' }"
        class="my-0 ms-0"
        value="2"
      >
        検索
      </v-tab>
    </v-tabs>
    <v-container
      fluid
      class="list-container pa-0"
    >
      <v-row
        justify="center"
        class="my-0 fill-height"
      >
        <v-col class="fill-height pt-0 pb-0">
          <router-view v-slot="{ Component }">
            <v-fade-transition mode="out-in">
              <component
                :is="Component"
                class="daytime_advice"
              />
            </v-fade-transition>
          </router-view>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TopMenu from "./shared/TopMenu.vue";

export default {
  name: "DaytimeAdviceHome",
  components: {
    TopMenu
  },
  emits: ["logout"],
  data: () => ({
  }),
  computed: {
    ...mapGetters(["currentDoctor"]),
  },
  watch: {
  },
  created() {
  },
  methods: {
    isOperator: function(){
      return this.currentDoctor && this.currentDoctor.type == "Operator::Doctor";
    },
  }
};
</script>

<style lang="scss">
.daytime_advice {
  font-size: 14px;
}
.top-tab {
  .v-btn:not(.v-tab--selected){
    .v-btn__content {
      color: rgb(0 0 0 / 0.54) !important;
    }
  }
}
</style>
