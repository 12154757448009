<template>
  <span>
    <span class="font-weight-bold">子</span>: {{ childFullProperty }}
  </span>
</template>

<script>
export default {
  name: "ChildFullProperty",
  props: {
    object: {
      type: Object,
      default: null
    },
    isChildNameDisplayable: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    childFullProperty: function () {
      if (this.object.childBirthdate == null) {
        return "";
      }

      if (this.isChildNameDisplayable === true) {
        return this.object.childBirthdate + " (" + this.object.childAge + ") ｜" + this.object.childSex + "｜" + this.object.childName;
      } else {
        return this.object.childBirthdate + " (" + this.object.childAge + ") ｜" + this.object.childSex;
      }
    }
  }
};
</script>
