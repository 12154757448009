<template>
  <div>
    <div>
      <b>ユーザID</b>
      <span class="pr-3">： {{ consultation.userId }}</span>
      <span v-if="consultation.needAttentionUser" class="btn-label">
        <v-chip color="pink" size="small" variant="outlined">
          <span class="hidden-sm-and-down">運営特記事項あり</span>
          <span class="hidden-md-and-up hidden-xs-only">特記事項あり</span>
          <span class="hidden-sm-and-up">特記あり</span>
        </v-chip>
      </span>
    </div>
    <div>
      <span><b>クーポン名</b>： {{ consultation.userCouponName }}</span>
    </div>
    <div>
      <span><b>学年</b>： {{ consultation.reservedGrade }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnytimeConsultationPediatricianYouthUserInfo",
  props: {
    consultation: {
      type: Object,
      default: null
    },
  },
};
</script>
