<template>
  <div>
    <top-menu
      ref="top-menu"
      @search="changeDate"
      @logout="$emit('logout')"
    />
    <v-toolbar
      height="64px"
      color="transparent"
    >
      <v-container
        fluid
      >
        <v-row>
          <v-col md="12">
            <v-toolbar-items>
              <v-btn
                :to="{ path: '/anytime_consultation_shifts', query: { date: $route.query.date } }"
                size="large"
                variant="outlined"
                color="pink-lighten-1"
                class="mr-4 shift-edit-btn cancel-btn pr-8 pl-8"
              >
                キャンセル
                <v-icon
                  icon="mdi-reply"
                  size="x-large"
                />
              </v-btn>
              <v-btn
                size="large"
                color="pink-lighten-1"
                class="shift-edit-btn save-btn pr-8 pl-8"
                @click="updateAcShift()"
              >
                保存する
                <v-icon
                  icon="mdi-send"
                  size="x-large"
                />
              </v-btn>
              <div
                v-if="errorMessage != null"
                style="padding-left:20px"
                class="pink--text"
              >
                {{ errorMessage }}<br>
                {{ anytimeConsultationShiftsUpdateErrorDetail }}
              </div>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-container
      fluid
      class="pa-0"
    >
      <v-row justify="center">
        <v-col class="pt-0 pb-0">
          <v-data-table-virtual
            :headers="anytimeConsultationShifts.headers"
            :header-props="{ nowrap: true }"
            :items="anytimeConsultationShifts.shifts"
            item-key="start_at"
            hide-default-footer
            disable-pagination
            hover
            class="elevation-1 ac-shift-list"
          >
            <template #item="{item}">
              <tr class="text-no-wrap">
                <td>{{ item.start_at }} 〜 {{ item.end_at }}</td>
                <td
                  v-for="(assign_shift, idx) in item.assign_shifts"
                  :key="`${assign_shift.id}_${idx}`"
                  style="min-width: 190px;"
                >
                  <template v-if="filterdDoctors(assign_shift.department_type)">
                    <v-autocomplete
                      v-if="anytimeConsultationShifts.shifts && !timeOver(item.start_at_with_date) && shifts[anytimeConsultationShifts.shifts.indexOf(item)]"
                      v-model="shifts[anytimeConsultationShifts.shifts.indexOf(item)][idx].doctor_id"
                      :name="assign_shift.doctor.name"
                      :items="filterdDoctors(assign_shift.department_type)"
                      item-title="name"
                      item-value="id"
                      required
                      variant="underlined"
                      class="doctor-select"
                    />
                  </template>
                  <div v-if="timeOver(item.start_at_with_date)">
                    {{ assign_shift.doctor.name }}
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table-virtual>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TopMenu from "./shared/TopMenu.vue";

export default {
  name: "AnytimeConsultationShiftEdit",
  components: {
    TopMenu
  },
  emits: ["logout"],
  computed: {
    ...mapGetters([
      "anytimeConsultationShifts",
      "doctorsByDepartmentType",
      "anytimeConsultationShiftsUpdateErrorDetail"
    ]),
  },
  data: () => ({
    shifts: [],
    shiftsUpdateResult: true,
    errorMessage: null,
    syounikaDoctors: [],
    obstetricsDoctors: [],
    midwifeDoctor: [],
  }),
  watch: {
    "$route" () {
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions(["updateAnytimeConsultationShifts"]),
    loadData() {
      this.$store.dispatch("fetchAnytimeConsultationShifts", {
        date: this.$route.query.date
      }).then(() => {
        // 編集内容を格納するモデルの初期化
        this.initShiftModel();
      });
      this.$store.dispatch("fetchDoctorsByDepartmentType").then(() => {
        this.createFilterdDoctors();
      });
    },
    changeDate(d) {
      this.$router.push({ name: "ac_shift", query: { date: d } });
    },
    updateAcShift(){
      this.updateAnytimeConsultationShifts(this.shifts).then(() => {
        this.$router.push({ name: "ac_shift", query: { date: this.$route.query.date } });
      }).catch(() => {
        // 保存エラー
        this.shiftsUpdateResult = false;
        this.errorMessage = "いつでも相談シフトの変更に失敗しました。管理者にお問い合わせください。";
      });
    },
    timeOver(start_at){
      // 当日より前はシフトを変更できないようにする
      let target = new Date(start_at);
      let today = new Date().setHours(0, 0, 0, 0);
      return target < today;
    },
    filterdDoctors(department_type){
      switch(department_type){
      case 1:
        return this.syounikaDoctors;
      case 2:
        return this.obstetricsDoctors;
      case 3:
        return this.midwifeDoctors;
      default:
        return [];
      }
    },
    createFilterdDoctors(){
      this.syounikaDoctors =  this.doctorsByDepartmentType(1);
      this.obstetricsDoctors = this.doctorsByDepartmentType(2);
      this.midwifeDoctors = this.doctorsByDepartmentType(3);
    },
    initShiftModel(){
      this.shifts = [];
      this.anytimeConsultationShifts.shifts.forEach((row, row_index) => {
        this.shifts[row_index] = [];
        row.assign_shifts.forEach((assign_shift, cell_idx) => {
          var data = {};
          data.start_at = row.start_at_with_date;
          data.end_at = row.end_at_with_date;
          data.department_type = assign_shift.department_type;
          data.shift_id = assign_shift.id;
          data.doctor_id = assign_shift.doctor.id;
          this.shifts[row_index][cell_idx] = data;
        });
      });
    }
  }
};
</script>
<style lang="scss">
.ac-shift-list{
  .v-select__slot {
    input {
      width: initial !important;
    }
  }
}

.shift-edit-btn.cancel-btn {
  font-size: 15px !important;
  background-color: #F6DDE6 !important;
}

.shift-edit-btn.save-btn {
  font-size: 15px !important;
  background-color: #ec407a !important;
  border-color: #ec407a !important;
  text-decoration: none !important;
  color: black !important;
}
</style>
