import passwordApi from "../../api/password";
import * as mutationTypes from "../mutation-types";
import router from "../../routes/index";


const state = {
  isResetEmailSent: false,
  isPasswordUpdated: false,
  passwordResetErrorDetail: "",
};


const getters = {
  isResetEmailSent: state => state.isResetEmailSent,
  isPasswordUpdated: state => state.isPasswordUpdated,
  passwordResetErrorDetail: state => state.passwordResetErrorDetail,
};


const actions = {
  sendResetEmail({ commit }, params) {
    return new Promise((resolve) => {
      passwordApi.sendResetEmail(isResetEmailSent => {
        commit(mutationTypes.ASSIGN_IS_RESET_EMAIL_SENT, { isResetEmailSent });
        resolve();
      }, params);
    });
  },

  ResetPassword ({ commit, dispatch, rootState }, params) {
    return new Promise((resolve, reject) => {
      passwordApi.ResetPassword (params,
        (loggedIn) => {
          commit(mutationTypes.ASSIGN_LOGGED_IN, { loggedIn });
          const isPasswordUpdated = true;
          commit(mutationTypes.ASSIGN_IS_PASSWORD_UPDATED, { isPasswordUpdated });
          dispatch("fetchCurrentDoctor", null, { root: true }).then(()=> {
            // 権限によりログイン先が変わる
            router.push(rootState.sessions.currentDoctor.homeUrl);
          });
          resolve();
        },
        (error) => {
          const passwordResetErrorDetail = error.response.data["message"].join("<br>");
          commit(mutationTypes.ASSIGN_PASSWORD_RESET_ERROR_DETAIL, { passwordResetErrorDetail });
          reject();
        },
      );
    });
  },
  hidePasswordUpdatedMessage({ commit }) {
    const isPasswordUpdated = false;
    commit(mutationTypes.ASSIGN_IS_PASSWORD_UPDATED, { isPasswordUpdated: isPasswordUpdated });
  },
};

const mutations = {
  [mutationTypes.ASSIGN_IS_RESET_EMAIL_SENT](state, { isResetEmailSent }) {
    state.isResetEmailSent = isResetEmailSent;
  },
  [mutationTypes.ASSIGN_PASSWORD_RESET_ERROR_DETAIL](state, { passwordResetErrorDetail }) {
    state.passwordResetErrorDetail = passwordResetErrorDetail;
  },
  [mutationTypes.ASSIGN_IS_PASSWORD_UPDATED](state, { isPasswordUpdated }) {
    state.isPasswordUpdated = isPasswordUpdated;
  },
};


export default {
  state,
  getters,
  actions,
  mutations
};
