<template>
  <v-app>
    <v-app-bar>
      <v-toolbar-title>KP相談カルテ</v-toolbar-title>
    </v-app-bar>
    <v-main
      theme="light"
      class="c-password"
    >
      <v-container
        fluid
        class="fill-height pa-4"
      >
        <v-row
          align-content="center"
          justify="center"
          dense
        >
          <v-col
            cols="12"
            md="5"
            class="p-0"
          >
            <v-card
              class="password-card"
            >
              <v-card-text>
                <v-row>
                  <v-col class="flex flex-col align-center justify-center">
                    <template v-if="isResetEmailSent === false">
                      <div
                        v-if="token === 'invalid'"
                        class="text-red text-center"
                      >
                        <p>URLの有効期限が過ぎています。</p>
                        <p>再度メールアドレスを入力してください。</p>
                      </div>
                      <h2 class="bold-text pa-4 text-center">パスワードをお忘れの方</h2>
                      <div style="text-align: center;">
                        <span
                          v-if="message != ''"
                          class="notice error-message text-red"
                          style="line-height: 1.5;"
                          v-html="message"
                        ></span>
                      </div>
                      <div class="pa-4">
                        <p>パスワードの再設定をします。</p>
                        <p>登録済みのメールアドレスを入力してください。</p>
                      </div>
                      <v-form>
                        <v-text-field
                          v-model="email"
                          class="my-2"
                          color="primary"
                          variant="underlined"
                          density="compact"
                          prepend-icon="mdi-account"
                          name="email"
                          label="メールアドレス"
                          type="text"
                        />
                      </v-form>
                      <v-spacer />
                      <v-btn
                        class="mx-auto mb-3"
                        color="teal"
                        style="width: 200px; font-size: 16px;"
                        @click="onSendResetEmail()"
                      >
                        本人確認メールを送信
                      </v-btn>
                      <v-btn
                        class="mx-auto"
                        style="width: 200px; font-size: 16px;"
                      >
                        <router-link to="/login" style="color: teal !important;">
                          戻る
                        </router-link>
                      </v-btn>
                    </template>
                    <template v-else>
                      <h2 class="bold-text pa-4 text-center">パスワード再設定メール送信完了</h2>
                      <div class="pa-4">
                        <p>・{{ email }}宛にパスワード再設定URLをお送りしました。メールが届いているか確認してください。</p>
                        <p>・記載されているURLをクリックし、パスワードの再設定を続けてください。</p>
                        <p>・数分お待ちいただいても届かない場合は、迷惑メールフォルダやゴミ箱もご確認ください。</p>
                        <p>・6時間以内に再発行手続きが完了しない場合はURLは無効になりますので、はじめからやり直してください。</p>
                      </div>
                    </template>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    token: {
      type: String,
      required: false,
    }
  },
  data: () => ({
    email: "",
    message: "",
  }),
  computed: {
    ...mapGetters(["isResetEmailSent"]),
  },
  methods: {
    ...mapActions(["sendResetEmail"]),
    onSendResetEmail: function() {
      this.sendResetEmail({
        email: this.email
      }
      ).then(() => {
        if(this.isResetEmailSent == false){
          this.message = "エラーが発生しました。<br>管理者にslackでお問い合わせください。";
        }
      });
    },
  },
};
</script>
<style lang="scss">
.c-password {
  .password-card.v-card--variant-elevated, 
  .password-card.v-card--variant-flat {
    color: #4F4F4F !important;
  }
  .notice {
    font-size: 1rem;
    font-weight: bold;
  }
  .v-field__input{
    padding: 8px 0;
  }

  .v-input--horizontal .v-input__prepend {
    margin-inline-end: 9px;
  }


  .v-btn {
    height: 30px;
    display: flex;
    line-height: 1.5;
    align-items: center;
    justify-content: center;
  }
  .btn-label .v-btn__content {
    font-size: 13px;
  }
  .bold-text {
    font-weight: bold;
  }
}
</style>
