<template>
  <v-row>
    <v-col
      md="12"
      class="pb-2"
    >
      <v-card>
        <v-toolbar
          color="#4D4D4D"
          height="32"
        >
          <v-toolbar-title>関連するいつでもカルテ一覧（他科・兄弟など）</v-toolbar-title>
        </v-toolbar>
        <v-card
          v-if="anytimeConsultations.length != 0"
          class="overflow-y-auto"
          max-height="400"
        >
          <v-card-item class="pa-0">
            <v-card
              v-for="consultation in anytimeConsultations"
              :key="consultation.id"
            >
              <v-card-text>
                <div>
                  <p class="mb-1">
                    {{ consultation.title }}
                  </p>
                  <p class="mb-1 d-flex">
                    {{ consultation.simpleAskedAt }} （{{ consultation.doctorName }}）
                  </p>
                  <child-property
                    v-if="consultation.departmentType === 'pediatrician'"
                    :object="consultation"
                    :is-child-name-displayable="false"
                  />
                  <p class="mb-1">
                    回答状況：{{ consultation.status }}
                  </p>
                  <p class="mb-1">
                    ICD10暫定診断：{{ consultation.icd10BranchName }}
                  </p>
                </div>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer />
                <a @click="openAnytimeConsultationView(consultation.id)">
                  <v-btn
                    color="black"
                    style="background-color: #EC407A;"
                  >
                    閲覧
                    <v-icon icon="mdi-eye" />
                  </v-btn>
                </a>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-card-item>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ChildProperty from "./ChildProperty.vue";

export default {
  name: "AnytimeConsultationKarteList",
  components: {
    ChildProperty
  },
  props: {
    title: {
      type: String,
      require: true,
      default: ""
    },
    anytimeConsultations: {
      type: Array,
      default: null
    },
    isChildNameDisplayable: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    openAnytimeConsultationView: (id) => {
      window.open("/anytime_consultations/view/" + id, "", "width=480, height=640");
    },
  }
};
</script>

<style lang="scss" scoped>
/* override */
.v-toolbar-title {
  color: #FFF;
  font-size: 12px;
  font-weight: bold;
}
</style>
