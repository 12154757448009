<template>
  <div>
    <top-menu
      ref="top-menu"
      @search="changeDate"
      @logout="$emit('logout')"
    />
    <v-toolbar
      height="64px"
      color="transparent"
    >
      <v-container
        fluid
      >
        <v-row>
          <v-col md="12">
            <v-toolbar-items>
              <v-btn
                :to="{ path: '/anytime_consultation_shifts/edit', query: { date: $route.query.date } }"
                size="large"
                class="shift-edit-btn edit-btn pr-8 pl-8"
              >
                編集する
                <v-icon
                  icon="mdi-border-color mt-1"
                  size="large"
                />
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-container
      fluid
      class="pa-0"
    >
      <v-row justify="center">
        <v-col class="pt-0 pb-0">
          <v-data-table-virtual
            :headers="anytimeConsultationShifts.headers"
            :header-props="{ nowrap: true }"
            :items="anytimeConsultationShifts.shifts"
            item-key="start_at"
            hide-default-footer
            disable-pagination
            hover
            class="elevation-1 ac-shift-list"
          >
            <template #item="{item}">
              <tr class="text-no-wrap">
                <td>{{ item.start_at }} 〜 {{ item.end_at }}</td>
                <td
                  v-for="(assign_shift, index) in item.assign_shifts"
                  :key="assign_shift.id"
                >
                  <div class="shift-value">
                    <button
                      v-if="isEditable(item.start_at_with_date)"
                      type="button"
                      @click="setEditShiftPosition(item.start_at, item.end_at, index)"
                    >
                      <v-icon
                        size="small"
                        start
                        icon="mdi-pencil"
                      />
                    </button>
                    <anytime-consultation-shift-value-edit
                      v-if="isVisibleEditForm(item.start_at, item.end_at, index)"
                      :assign-shift="assign_shift"
                      :start-at="item.start_at_with_date"
                      :end-at="item.end_at_with_date"
                      :doctors="filterdDoctors(assign_shift.department_type)"
                      @close="close"
                    />
                    <anytime-consultation-shift-value
                      v-if="!isVisibleEditForm(item.start_at, item.end_at, index)"
                      :doctor="assign_shift.doctor"
                    />
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table-virtual>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TopMenu from "./shared/TopMenu.vue";
import AnytimeConsultationShiftValue from "./shared/ac_shift/AnytimeConsultationShiftValue.vue";
import AnytimeConsultationShiftValueEdit from "./shared/ac_shift/AnytimeConsultationShiftValueEdit.vue";

export default {
  name: "AnytimeConsultationShift",
  components: {
    TopMenu,
    AnytimeConsultationShiftValue,
    AnytimeConsultationShiftValueEdit
  },
  emits: ["logout"],
  data: () => ({
    editShiftPosition: {
      start_at: null,
      end_at: null,
      index: null
    },
    syounikaDoctors: [],
    obstetricsDoctors: [],
    midwifeDoctor: [],
  }),
  computed: {
    ...mapGetters([
      "anytimeConsultationShifts",
      "doctorsByDepartmentType",
    ]),
  },
  watch: {
    "$route" () {
      this.loadData();
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$store.dispatch("fetchAnytimeConsultationShifts", {
        date: this.$route.query.date
      });
      this.$store.dispatch("fetchDoctorsByDepartmentType").then(() => {
        this.createFilterdDoctors();
      });
    },
    changeDate(d) {
      this.$router.push({ name: "ac_shift", query: { date: d } });
    },
    createFilterdDoctors() {
      this.syounikaDoctors =  this.doctorsByDepartmentType(1);
      this.obstetricsDoctors = this.doctorsByDepartmentType(2);
      this.midwifeDoctors = this.doctorsByDepartmentType(3);
    },
    filterdDoctors(department_type) {
      switch(department_type){
      case 1:
        return this.syounikaDoctors;
      case 2:
        return this.obstetricsDoctors;
      case 3:
        return this.midwifeDoctors;
      }
    },

    isEditable(start_at){
      // 前日以前のシフトを変更できないようにする
      let target = new Date(start_at);
      let today = new Date().setHours(0, 0, 0, 0);
      return target >= today;
    },

    close: function () {
      this.setEditShiftPosition(null, null, null);
    },

    setEditShiftPosition(start_at, end_at, index) {
      this.editShiftPosition.start_at = start_at;
      this.editShiftPosition.end_at = end_at;
      this.editShiftPosition.index = index;
    },

    isVisibleEditForm(start_at, end_at, index) {
      return (
        this.editShiftPosition.start_at === start_at
        && this.editShiftPosition.end_at === end_at
        && this.editShiftPosition.index === index
      );
    }
  }
};
</script>
<style lang="scss">
.v-data-table.ac-shift-list tbody td:first-child,
.v-data-table.ac-shift-list tbody td:not(:first-child),
.v-data-table.ac-shift-list tbody th:first-child,
.v-data-table.ac-shift-list tbody th:not(:first-child),
.v-data-table.ac-shift-list thead td:first-child,
.v-data-table.ac-shift-list thead td:not(:first-child),
.v-data-table.ac-shift-list thead th:first-child,
.v-data-table.ac-shift-list thead th:not(:first-child) {
    padding: 0 24px;
}
.v-data-table.shift-list thead tr {
  height: 46px;
}
.v-data-table.ac-shift-list th {
  font-weight: 500;
}
.v-data-table.ac-shift-list table {
  margin-top: 10px;
}
.v-data-table.ac-shift-list tbody td {
    font-weight: 400;
    font-size: 13px;
}
.v-data-table.ac-shift-list tbody td .shift-value {
  display: flex;
}
.shift-edit-btn.edit-btn {
  font-size: 15px !important;
  text-decoration: none !important;
  background-color: #ec407a !important;
  border-color: #ec407a !important;
  color: black !important;
}
</style>
