import "vuetify/styles";
import "@/styles/global.scss";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";


// TODO: 一旦デフォルトの設定を有効にしているが、利用していたプラグインを有効化する費強グア有る
// import sanitizeHTML from "sanitize-html";
// Vue.prototype.$sanitize = sanitizeHTML;
// const options = {};
// export default new Vuetify(options);

// const light = {};

// const dark = {};

// const vuetify = createVuetify({
//   theme: {
//     defaultTheme: "light",
//     themes: {
//       light,
//       dark,
//     },
//   },
//   // TODO
//   //   icons: {
//   //     defaultSet: "mdi",
//   //   },
//   //   options: {
//   //     customProperties: true
//   //   },
//   components,
//   directives,
// });

// export default vuetify;

const light = {
  dark: false,
  colors: {
    background: "#fafafa",
  },
};

const dark = {
  dark: true,
  colors: {},
};

const vuetify = createVuetify({
  theme: {
    defaultTheme: "light",
    themes: {
      light,
      dark,
    },
  },
  // icons: {
  //   defaultSet: "mdi",
  // },
  options: {
    customProperties: true
  },
  components,
  directives,
});

export default vuetify;
