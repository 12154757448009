<template>
  <v-container
    grid-list-xs
    fluid
    class="pa-2 pt-0 pb-0"
    v-if="anytimeConsultations"
  >
    <v-card
      :flat="true"
      class="anytime-consultation-search-info pl-2 pt-2"
    >
      <v-card-text class="pa-0">
        {{ order }}に表示 / {{ anytimeConsultations.length }}件ヒット
      </v-card-text>
    </v-card>
    <v-container fluid>
      <div
        v-for="consultation in anytimeConsultations"
        :key="consultation.id"
        class="anytime-consultation-info"
      >
        <anytimeConsultationDetail
          :anytimeConsultation = "consultation"
        />

        <v-row
          v-if="consultation.answer"
          noguide
          class="anytime-consultation-info-answer"
        >
          <v-col
            cols="12"
            class="pa-0"
          >
            <v-textarea
              v-model="consultation.answer"
              label="回答"
              rows="10"
              class="text-grey"
              color="grey-darken-4"
              variant="outlined"
              hide-details
              :readonly="true"
              @mousedown="logCopyEvent(consultation)"
            />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import AnytimeConsultationDetail from "./AnytimeConsultationDetailCard.vue";

export default {
  name: "AnytimeConsultationSearchResults",
  components: {
    AnytimeConsultationDetail
  },
  props: ["anytimeConsultations", "order", "previousKeys"],
  computed: {
    ...mapGetters([
      "currentDoctor",
    ]),
  },
  methods: {
    logCopyEvent: function(consultation) {
      if (!this.previousKeys) return;

      axios.post("/api/v1/anytime_consultations/log_copy_event", {
        consultation: consultation,
        search_keys: this.previousKeys
      }).then(
      ).catch(error => {
        console.log(error);
      });
    },
  }
};
</script>

<style lang="scss">
  .anytime-consultation-search-info {
    background-color: #fafafa;
    margin: 2px;

    .v-card__text {
      background-color: #fafafa;
    }
  }

  .anytime-consultation-info {
    border: solid 1px #888888;
    background-color: white;
    border-radius: 2px;

    .v-card {
      border: solid 1px #bdbdbd !important;
      height: 100%;
    }

    .anytime-consultation-info-answer {
      margin: 6px;
    }
  }
</style>
