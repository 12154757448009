<template>
  <p class="mb-1">
    子：{{ childProperty }}
  </p>
</template>

<script>
export default {
  name: "ChildProperty",
  props: {
    object: {
      type: Object,
      default: null
    },
    isChildNameDisplayable: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    childProperty: function () {
      if (this.object.childBirthdate == null) {
        return "";
      }

      if (this.isChildNameDisplayable === true) {
        return this.object.childBirthdate + " | " + this.object.childName;
      } else {
        return this.object.childBirthdate;
      }
    }
  }
};
</script>
