<template>
  <v-row>
    <v-col
      md="12"
      class="pt-2 pb-2"
    >
      <v-card>
        <v-toolbar
          color="#4D4D4D"
          height="32"
        >
          <v-toolbar-title>{{ title }}<slot /></v-toolbar-title>
        </v-toolbar>
        <v-card
          v-if="karteList.length != 0"
          class="overflow-y-auto"
          max-height="400"
        >
          <v-card-item class="pa-0">
            <v-card
              v-for="karte in karteList"
              :key="karte.id"
            >
              <v-card-text>
                <div>
                  <karte-department-name
                    :department-type="karte.departmentType"
                  />
                  <p class="mb-1 d-flex">
                    {{ karte.date }} {{ karte.time }} 〜 （{{ karte.doctorName }}）
                    <v-img
                      v-if="karte.message"
                      src="/send_off.png"
                      width="60"
                      height="22"
                      position="center top"
                    />
                  </p>
                  <child-property
                    v-if="karte.departmentType === 1 && karte.hasChild"
                    :object="karte"
                    :is-child-name-displayable="isChildNameDisplayable"
                  />
                  <p class="mb-1">
                    主訴：{{ karte.complaint }}
                  </p>
                  <p
                    v-if="karte.diagnosis"
                    class="mb-1"
                  >
                    旧暫定診断：{{ karte.diagnosis }}
                  </p>
                  <p class="mb-1">
                    ICD10暫定診断：{{ karte.icd10BranchName }}
                  </p>
                  <p class="mb-1">
                    転帰：{{ karte.outcomeId }}
                  </p>
                </div>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer />
                <a @click="openKarte(karte.reservationId)">
                  <v-btn
                    color="black"
                    style="background-color: #EC407A;"
                  >
                    閲覧
                    <v-icon icon="mdi-eye" />
                  </v-btn>
                </a>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-card-item>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ChildProperty from "./ChildProperty.vue";
import KarteDepartmentName from "../KarteDepartmentName.vue";

export default {
  name: "SiblingKarteList",
  components: {
    ChildProperty,
    KarteDepartmentName
  },
  props: {
    title: {
      type: String,
      required: true
    },
    karteList: {
      type: Array,
      required: true
    },
    isChildNameDisplayable: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    openKarte: (id) => {
      window.open("/reservations/" + id, "", "width=480, height=640");
    }
  }
};
</script>

<style lang="scss" scoped>
/* override */
.v-toolbar-title {
  color: #FFF;
  font-size: 13px;
  font-weight: bold;
}
</style>
