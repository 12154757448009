<template>
  <v-container
    fluid
    class="pa-2 daytime-advice-sheet"
  >
    <v-row class="pa-4 pt-6 pb-0">
      <v-col
        cols="3"
        sm="3"
        md="3"
        class="pt-0"
      >
        <daytime-advice-sheet-addon
          v-if="daytimeAdviceSheet.userId != null"
          ref="addon"
          :active="editing"
          :user-id="daytimeAdviceSheet.userId"
        />
      </v-col>
      <v-col
        cols="5"
        sm="5"
        md="5"
        class="pt-0"
      >
        <v-row
          v-if="notStartedYet"
          class="pa-0 align-center justify-center"
        >
          <v-col
            cols="6"
            sm="6"
            md="12"
          >
            <div style="background-color:#FFEEF1; display:flex; align-items:center; padding:6px; gap:4px;">
              <v-icon
                size="16"
                style="color:#D72E4B;"
              >
                mdi-alert-circle-outline
              </v-icon>
              <span style="font-size:12px; color:#D72E4B;">次の助産師に引き継ぐ場合、「未対応」のまま「担当終了」を押下（「対応中」は「相談を終える」を押下）️</span>
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="!editing"
          class="px-0 pb-0 pt-3 align-end flex-column"
        >
          <v-col
            cols="6"
            sm="6"
            md="6"
            class="pl-0 pt-0 justify-end"
          >
            <v-btn
              :color="notStartedYet ? 'grey' : 'pink'"
              class="font-weight-bold"
              block
              theme="dark"
              @click="notStartedYet ? disableEditing() : startEditing()"
            >
              編集する
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="editing"
          class="px-0 pb-0 pt-3"
        >
          <v-col
            cols="6"
            sm="6"
            md="6"
            class="pt-0"
          >
            <v-btn
              variant="outlined"
              color="pink"
              block
              @click="cancel()"
            >
              キャンセル
            </v-btn>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            md="6"
            class="pt-0"
          >
            <v-btn
              color="pink"
              class="font-weight-bold"
              block
              theme="dark"
              @click="saveSheet()"
            >
              保存する
            </v-btn>
          </v-col>
        </v-row>
        <daytime-advice-sheet-body
          :editing="editing"
        />
        <v-row>
          <v-col>
            <p class="text-md-center pink--text">
              ※ 運営からのお知らせ ※
            </p>
            <p
              style="white-space: pre-wrap; line-height: 25px"
              v-html="daytimeAdviceReferenceInfo.content"
            />
          </v-col>
        </v-row>
        <daytime-advice-images />
      </v-col>
      <v-col
        cols="4"
        sm="4"
        md="4"
        class="pa-4 pt-0"
        style="height: 100%;"
      >
        <v-btn
          v-if="!finished"
          :color="isActiveFinishButton() ? 'blue' : 'grey'"
          class="font-weight-bold ml-0"
          block
          theme="dark"
          @click="clickFinishButton()"
        >
          相談を終える
        </v-btn>
        <!--// チャット画面 -->
        <chat
          v-if="daytimeAdviceSheet.id != null"
          ref="chat"
          :message-disabled="finished"
          :chat-id="parseInt($route.params.id)"
          :department-type="daytimeAdviceSheet.departmentType"
          log-type="daytimeAdvice"
          :coupon-name="daytimeAdviceSheet.couponName"
          @afterSendingChat="reloadDaytimeAdviceSheet"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Chat from "./shared/Chat.vue";
import DaytimeAdviceSheetBody from "./shared/DaytimeAdviceSheetBody.vue";
import DaytimeAdviceSheetAddon from "./shared/DaytimeAdviceSheetAddon.vue";
import DaytimeAdviceImages from "./shared/DaytimeAdviceImages.vue";


export default {
  name: "DaytimeAdviceSheet",
  components: {
    Chat,
    DaytimeAdviceSheetBody,
    DaytimeAdviceSheetAddon,
    DaytimeAdviceImages
  },
  data: () => ({
    editing: false,
    isSaved: false
  }),
  computed: {
    ...mapGetters([
      "daytimeAdviceSheet",
      "daytimeAdviceReferenceInfo",
    ]),
    finished: function (){
      return this.daytimeAdviceSheet.finished;
    },
    noAdvice: function (){
      return this.daytimeAdviceSheet.noAdvice;
    },
    notStartedYet: function() {
      return this.daytimeAdviceSheet.status == "not_started_yet";
    },
    inProcess: function() {
      return this.daytimeAdviceSheet.status == "in_process";
    }
  },
  watch: {
    "$route" () {
      this.clearData();
      this.fetchData();
    }
  },
  created() {
    this.fetchData();
  },
  unmounted() {
    this.clearData();
  },
  methods: {
    ...mapActions(["updateDaytimeAdviceSheet"]),
    fetchData: function() {
      this.$store.dispatch("clearDaytimeAdviceSheet");

      this.$store.dispatch("fetchDaytimeAdviceSheet", {
        id: this.$route.params.id
      });
      this.$store.dispatch("fetchDaytimeAdviceReferenceInfo", {
        id: this.$route.params.id
      });
    },
    clearData: function(){
      this.$store.dispatch("clearDaytimeAdviceSheet");

      // 編集状態など画面を制御しているフラグをリセット
      this.editing = false;
      this.isSaved = false;
    },
    startEditing: function() {
      this.editing = true;
    },
    saveSheet: function() {
      this.$refs.addon.updateUser(this.$refs.addon.user);
      this.updateDaytimeAdviceSheet(this.daytimeAdviceSheet).then(() => {
        // TODO: エラーハンドリング
        this.editing = false;
        this.isSaved = true;
      });
    },
    endEditing: function() {
      this.editing = false;
    },
    finishAdvice: function() {
      if(confirm("相談を終了します。よろしいですか？")) {
        this.$store.dispatch("finishDaytimeAdviceSheet", {
          id: this.$route.params.id
        }).then(() => {
          this.$router.push({ path: "/daytime_advices/room" });
        });
      }
    },
    cancel: function(){
      this.endEditing();
      // 入力データをリセットする
      this.$store.dispatch("fetchDaytimeAdviceSheet", {
        id: this.$route.params.id
      });
      this.$refs.addon.findUser();
    },
    disableEditing: function() {
      alert("今の状態では、カルテを編集できません。次の対応をお願いします。\n・メッセージを送付");
    },
    clickFinishButton: function() {
      if (this.notStartedYet) {
        // 未対応
        alert("今の状態では、「相談を終える」を押せません。以下の対応をお願いします。\n\n・次の助産師に担当してもらう場合、もしくは営業時間終了で対応不可の場合、「担当終了」\n・ご自身で担当する場合、チャットを送付");
      } else if (this.inProcess && this.editing) {
        // 対応中 && カルテ編集中
        alert("今の状態では、「相談を終える」を押せません。以下の対応をお願いします。\n\n・カルテを保存");
      } else if (this.inProcess && this.noAdvice === null) {
        // 対応中 + 相談未選択
        alert("今の状態では、「相談を終える」を押せません。以下の対応をお願いします。\n\n・「相談あり」「相談なし」どちらか必ず選択し、保存");
      } else {
        // 対応中 + 相談選択済み + カルテを保存ボタン押下後
        this.finishAdvice();
      }
    },
    isActiveFinishButton: function() {
      // 対応中 + 相談選択済み + 編集中ではない
      // MEMO: noAdviceに何かしら入っていれば保存された状態と判断する
      return this.inProcess && this.noAdvice !== null && !this.editing;
    },
    reloadDaytimeAdviceSheet: function() {
      if (this.notStartedYet){
        // ステータスが変更されているので再取得する
        this.$store.dispatch("fetchDaytimeAdviceSheet", {
          id: this.$route.params.id
        });
      }
    },
  }
};
</script>
