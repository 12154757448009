<template>
  <v-container
    fluid
    style="background-color: #fdeef3"
    class="daytime-advice-sheet-view"
  >
    <v-row class="ma-2 bg-white">
      <v-col style="padding: 12px;">
        <v-container fluid class="pt-0 pb-0">
          <v-row class="pa-0">
            <v-col class="px-3 pt-3 pb-0">
              <span style="font-size: 1.17em; font-weight: bold;">{{ sheet.adviceDate }} {{ sheet.startAt }}</span>
            </v-col>
          </v-row>
          <v-row class="pl-4 pr-4 py-2">
            <v-col class="pa-0">
              <v-container fluid class="pa-2">
                <v-row class="pa-0" style="border: solid 1px #bdbdbd">
                  <v-col
                    md="5"
                    sm="5"
                    cols="5"
                    class="pa-0"
                    style="border-right: solid 1px #bdbdbd"
                  >
                    <v-card :flat="true" ripple>
                      <v-card-text class="pa-2">
                        <span v-text="sheet.doctorName" />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col md="7" sm="7" cols="7" class="pa-0">
                    <v-card :flat="true" ripple>
                      <v-card-text class="pa-2">
                        <b>ユーザ</b>
                        <span
                          >： {{ sheet.userName }} (ID:
                          {{ sheet.userId }})</span
                        >
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
        <div class="pl-3 mt-3">
          <daytime-advice-sheet-body />
        </div>
        <div class="pl-3 pt-2">
          <chat-view
            :chat-id="parseInt($route.params.id)"
            log-type="daytimeAdvice"
          />
        </div>
        <div class="pl-3">
          <daytime-advice-images />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ChatView from "./shared/ChatView.vue";
import DaytimeAdviceSheetBody from "./shared/DaytimeAdviceSheetBody.vue";
import DaytimeAdviceImages from "./shared/DaytimeAdviceImages.vue";

export default {
  name: "DaytimeAdviceSheetView",
  components: {
    ChatView,
    DaytimeAdviceSheetBody,
    DaytimeAdviceImages,
  },
  data: () => ({
    editing: false,
  }),
  computed: {
    ...mapGetters({
      sheet: "daytimeAdviceSheet",
    }),
  },
  watch: {
    $route() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData: function () {
      this.$store.dispatch("fetchDaytimeAdviceSheet", {
        id: this.$route.params.id,
      });
    },
  },
};
</script>
<style lang="scss">
/* override */
.daytime-advice-sheet-view .v-toolbar__title {
  color: #fff;
  font-size: 14px;
}
.daytime-advice-sheet-view
  div.v-input--disabled.v-text-field
  .v-text-field__slot
  textarea {
  color: #212121 !important;
}
</style>
