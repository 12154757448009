<template>
  <v-app>
    <v-alert
      v-if="timeoutApproaching"
      type="warning"
    >
      一定時間操作が行われていない場合、自動的にログアウトされます
    </v-alert>
    <v-alert
      v-if="timeout"
      type="success"
    >
      長時間操作が行われなかったため、自動的にログアウトされました
    </v-alert>
    <v-snackbar
      v-model="isPasswordUpdated"
      :timeout="3000"
      color="green"
      top
    >
      パスワードを変更しました
    </v-snackbar>
    <router-view
      @logout="logout"
      @setUpNotice="connectionNoticeChannel"
      @setUpLogoutTimer="setUpLogoutTimer"
    />
    <div
      v-if="notice"
      id="chat-notice"
      @click="hideNotice"
    >
      <div class="new-message">
        {{ noticeMessage.senderName }} さんから新着LINEメッセージがあります</br>
        {{ noticeMessage.reservationDate }} {{ noticeMessage.reservationTime }}
      </div>
      <router-link :to="{ path: 'karte', query: { room: noticeMessage.roomSection, reservation_id: noticeMessage.reservationId }}">
        LINEメッセージを見る
      </router-link>
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import timestampMixin from "./mixins/timestamp.js";
import audioMixin from "./mixins/audio.js";

const TIMEOUT_APPROACHING_SEC = 6600;
const TIMEOUT_SEC = 7200;

export default {
  name: "App",
  mixins: [timestampMixin, audioMixin],
  data() {
    return {
      messageChannel: null,
      notice: false,
      noticeMessage: null,
      timeout: false,
      logoutTimerId: null,
    };
  },

  computed: {
    ...mapGetters([
      "currentDoctor",
      "reservationTimeoutId",
      "periodListTimeoutId",
      "daytimeAdviceTimeoutId",
      "assignedAdviceTimeoutId",
      "passedSec",
      "timeoutApproaching",
      "isPasswordUpdated",
    ]),
  },

  created() {
    this.$store.dispatch("checkLoggedIn").then(() => {
      this.setLogoutTimer();
      console.log("checkLoggedIn");
      this.connectionNoticeChannel();
    });
  },

  mounted() {
    if (this.$route.name === "reservations") {
      window.setTimeout(this.showWelcomeDialog(), 500);
    }
    if (this.isPasswordUpdated) {
      this.$store.dispatch("hidePasswordUpdatedMessage");
    }
  },

  beforeUnmount () {
    this.disConnectionNoticeChannel();
    // 自動更新を停止する
    this.stopReloadAll();
  },

  methods: {
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.disConnectionNoticeChannel();
        // 自動更新を停止する
        this.stopReloadAll();
      });
    },
    countPassedSec : function(){
      this.$store.dispatch("countUp");
      if( this.passedSec === TIMEOUT_APPROACHING_SEC ) {
        // clearTimeoutApproachingは、routing制御時に実行する
        this.$store.dispatch("noticeTimeoutApproaching");
      } else if( this.passedSec > TIMEOUT_SEC ){
        this.clearTimeout();
        if( this.currentDoctor ){
          this.showTimeoutAlert();
          this.logout();
        }
      }
    },
    setUpLogoutTimer: function(){
      this.hideTimeoutAlert();
      this.setLogoutTimer();
    },
    setLogoutTimer: function(){
      this.logoutTimerId = setInterval(this.countPassedSec, 1000);
    },
    clearTimeout: function(){
      // reloadのタイマー処理を止める
      clearTimeout(this.reloadTimeoutId);
      this.$store.dispatch("assignReloadTimeoutId", null);
    },
    showTimeoutAlert(){
      this.timeout = true;
    },
    hideTimeoutAlert(){
      this.timeout = false;
    },
    connectionNoticeChannel: function(){
      console.log(this.timestamp() + " connection Notice Message Channel.");
      var that = this;
      this.disConnectionNoticeChannel();
      this.messageChannel = this.$cable.subscriptions.create(
        {
          channel: "NoticeMessageChannel",
        },
        {
          received: (data) =>  {
            console.log(that.timestamp() + " received notice message.");
            var noticeMessage = JSON.parse(data["notice"]);
            // 該当カルテを開いていない場合のみ通知する
            if(!(that.$route.path != "reservations/karte" && that.$route.query.room == noticeMessage.roomSection && that.$route.query.reservation_id == noticeMessage.reservationId)){
              this.showNotice(noticeMessage);
              this.soundNotify();
            }
          },
          connected: function() {
            console.log(that.timestamp() + " connected notice message channel.");
          },
          disconnected: function() {
            console.log(that.timestamp() + " disconnected notice message channel.");
          },
          rejected: function() {
            console.log(that.timestamp() + " rejected notice message channel.");
          },
        }
      );
    },
    disConnectionNoticeChannel: function(){
      if(this.messageChannel == null) return;
      console.log(this.timestamp() + " disconnection Notice Message Channel");
      this.messageChannel.unsubscribe();
    },
    showNotice(noticeMessage){
      this.notice = true;
      this.noticeMessage = noticeMessage;
    },
    hideNotice(){
      this.notice = false;
      this.noticeMessage = null;
    },
    stopReloadAll(){
      // 自動更新を停止する
      this.stopReload(this.reservationTimeoutId);
      this.stopReload(this.periodListTimeoutId);
      this.stopReload(this.daytimeAdviceTimeoutId);
      this.stopReload(this.assignedAdviceTimeoutId);
      this.stopReload(this.logoutTimerId);
    },
    stopReload(timeoutId){
      if(timeoutId){
        // リロードをやめる
        clearInterval(timeoutId);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
#chat-notice {
  position: fixed;
  top: 20px;
  right: 30px;
  bottom: auto;
  height: max-content;
  z-index: 200000;
  background-color: #f8bbd0;
  width: 300px;
  border: 1px solid #f8bbd0;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;

  span.notice-title {
    font-weight: bold;
    color: #ec407a;
  }

  div.new-message {
    color: #757575;
    font-size: 12px;
  }
}
</style>
<style lang="scss">
.secure-image {
  pointer-events: none;
}
</style>
