<template>
  <div>
    <div class="diagnosis-form">
      <v-textarea
        v-model="karte.icd10BranchName"
        variant="outlined"
        name="diagnosis"
        required
        label="暫定診断（必須）*"
        rows="2"
        :readonly="true"
        :disabled="!active"
        class="pt-0 pb-0"
        hide-details="false"
        @click="open"
      />
      <button
        type="button"
        :disabled="!active"
        class="diagnosis-delete-btn"
        @click="clearDiagnosis()"
      >
        選択解除
      </button>
    </div>
    <v-dialog
      v-model="viewModal"
      title="暫定診断"
      name="diagnosis"
      width="auto"
    >
      <div
        class="panel"
        @click.stop
      >
        <div class="modal-header">
          <h3>暫定診断</h3>
          <span>※メインの相談を1つ選択、「疑い」でも選択</span>
          <span
            class="close"
            @click="close"
          >X</span>
        </div>
        <div :class="`panel-body ${departmentType === 'obstetrics' ? 'panel-body-obstetrics' : 'panel-body-pediatrician'}`">
          <div class="panel-box">
            <div
              v-for="categories in frequentCategories[0]"
              class="flexItem"
            >
              <div class="category-box">
                <div
                  v-for="category in categories"
                  class="category"
                >
                  <h5>＜{{ category.sub_category }}＞</h5>
                  <ul class="item-list">
                    <li v-for="branch in category.branches">
                      <span @click="onSelectDiagnosis(branch)">{{ branch.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-box right">
            <div class="categories">
              <div
                v-for="(categories, index) in frequentCategories[1]"
                class="flexItem"
              >
                <div class="category-box">
                  <div
                    v-for="category in categories"
                    class="category"
                  >
                    <h5>＜{{ category.sub_category }}＞</h5>
                    <ul class="item-list">
                      <li v-for="branch in category.branches">
                        <span @click="onSelectDiagnosis(branch)">{{ branch.name }}</span>
                      </li>
                    </ul>
                  </div>
                  <div
                    v-if="index == 1"
                    class="others"
                  >
                    <h5>＜その他＞</h5>
                    <template v-if="otherFrequent">
                      <ul class="item-list">
                        <li v-for="branch in otherFrequent.branches">
                          <span @click="onSelectDiagnosis(branch)">{{ branch.name }}</span>
                        </li>
                      </ul>
                    </template>

                    <small>独自病名入力</small>
                    <div class="originalDiseaseField">
                      <input
                        id="originalDisease"
                        v-model="karte.icd10OriginalDisease"
                        type="text"
                        @input="displayReflectLink"
                      ></input>
                      <span
                        v-if="viewReflectText"
                        class="reflect"
                        @click="onSetOriginalDisease"
                      >
                        反映
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="search-area center">
              <h4>検索</h4>
              <input
                id="searchField"
                v-model="keyword"
                type="text"
                @input="searchIcd10"
              ></input>
              <div class="resultField">
                <div class="notice-text">
                  <p>
                    ※ 左になければ検索してください。
                  </p>
                  <p>
                    ※ 検索して選択肢が出てこなかった場合は、かなでの入力、漢字での入力などを試してみてください。
                  </p>
                  <p v-if="departmentType === 'obstetrics'">
                    ※ どうしても該当する診断名が見つからない健康相談は、検索で[保健相談]を選択してください。
                  </p>
                </div>
                <div :class="{ listWrap: searchResults.length != 0 }">
                  <div class="listArea">
                    <div v-for="result in searchResults">
                      <p @click="onSelectDiagnosis(result)">
                        {{ result.name }}
                      </p>
                    </div>
                  </div>
                  <p
                    v-if="searchResults.length != 0"
                    class="itemNotice"
                  >
                    ※ 検索して選択肢が出てこなかった場合は、かなでの入力、漢字での入力などを試してみてください。</br>
                    <span v-if="departmentType === 'obstetrics'">
                      ※ どうしても該当する診断名が見つからない健康相談は、検索で[保健相談]を選択してください。
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
    <div class="mb-5" />
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "Diagnosis",
  model: {
    prop: "karte"
  },
  props: {
    active: Boolean,
    departmentData: String,
    karte: Object,
    modelValue: Object,
  },
  data: () => ({
    frequentCategories: [],
    otherFrequent: null,
    viewModal: false,
    viewReflectText: false,
    keyword: "",
    searchResults: []
  }),
  computed: {
    departmentType: function () {
      return (this.departmentData == "1" || this.departmentData == "pediatrician") ? "syounika" : "obstetrics";
    },
  },
  watch: {
    viewModal(newValue) {
      if(newValue == true) {
        this.getFrequentCategories(this.departmentType);
        // 画面遷移した時、前のモーダルの情報が残ってしまうのでクリアする
        this.viewReflectText = false;
        this.searchResults.splice(0);
        this.keyword = "";
      }
    }
  },
  methods: {
    onSelectDiagnosis(item) {
      this.karte.branch_id = item.id;
      this.karte.icd10BranchName = item.name;
      this.karte.original_disease = null;
      this.karte.icd10OriginalDisease = null;
      this.close();
    },
    onSetOriginalDisease: function () {
      this.karte.branch_id = null;
      this.karte.icd10BranchName = document.getElementById("originalDisease").value;
      this.karte.icd10OriginalDisease = document.getElementById("originalDisease").value;
      this.karte.original_disease = document.getElementById("originalDisease").value;
      this.close();
    },
    clearDiagnosis() {
      this.karte.branch_id = null;
      this.karte.icd10BranchName = null;
      this.karte.original_disease = null;
      this.karte.icd10OriginalDisease = null;
    },
    displayReflectLink: function () {
      if(document.getElementById("originalDisease").value.length == 0) {
        this.viewReflectText = false;
      } else {
        this.viewReflectText = true;
      }
    },
    searchIcd10: function () {
      this.keyword = document.getElementById("searchField").value;
      axios.get("/api/v1/icd10/" + this.departmentType + "/search", {
        params: {
          keyword: this.keyword
        }
      }).then(response => {
        this.searchResults = response.data;
      }).catch(error => {
        console.log(error);
      });
    },
    getFrequentCategories: function(type) {
      // NOTE: ここで配列をspliceで削除しないとうまく変更が検知されず、テンプレートが再描画されない
      this.frequentCategories.splice(0);
      axios.get("/api/v1/icd10/" + type + "/frequent_categories", {
      }).then(response => {
        // 表示する列ごとに分割して格納しておく
        let frequent_categories = response.data.frequent_categories
        if(type == "syounika") {
          this.frequentCategories.push([frequent_categories.slice(0, 2), frequent_categories.slice(2, 5), frequent_categories.slice(5, 8)]);
          this.frequentCategories.push([frequent_categories.slice(8, 10), frequent_categories.slice(10)]);
        } else {
          this.frequentCategories.push([[frequent_categories[0]], [frequent_categories[1]]]);
          this.frequentCategories.push([[frequent_categories[2]], []]);
          // 「その他」カテゴリは特殊扱い
          this.otherFrequent = frequent_categories[3];
        }
      }).catch(error => {
        console.log(error);
      });
    },
    open () {
      this.viewModal = true;
    },
    close () {
      this.viewModal = false;
    },
  }
};
</script>
<style lang="scss" scoped>
.diagnosis-form {
  position: relative;
  .diagnosis-delete-btn {
    position: absolute;
    right: 0;
    color: #C0C0C0;
    &:hover {
      cursor: pointer;
      color: #CDCDCD;
    }
    &:disabled {
      display: none;
    }
  }
}
.panel {
  min-width: 850px;
  min-height: 550px;
  background: #fff;
  padding: 0;
  font-size: 14px;

  .modal-header {
    position: relative;
    width: 100%;
    background-color: #e0e0e0;
    padding: 5px;
    margin-bottom: 10px;
    text-align: center;

    h3 {
      display: inline;
      margin-right: 5px;
      font-size: 1.17rem
    }
    .close {
      position: absolute;
      font-size: 150%;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }
  }
  h4 {
    text-align: center;
  }
}

.panel-body {
  padding: 5px 20px;
  display: flex;
  flex-direction: row;
}

.panel-box {
  display: flex;
  justify-content: space-around;
}
.panel-body-obstetrics {
  height: 500px;
}

.panel-body-pediatrician {
  height: 550px;
}

.panel-box:nth-child(1) {
  width: 60%;
}
.panel-box:nth-child(2) {
  width: 40%;
}
.panel-box.right {
  height: 500px;
  display: flex;
  flex-direction: column;
}
.categories {
  height: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.category-box {
  .category {
    margin-bottom: 10px;
    margin-left: 5px;
    flex-grow: 0;
  }
}

.flexItem {
  width: 100%;
}

.item-list {
  margin-top: 5px;
  padding-left: 24px;

  li {
    margin-bottom: 3px;

    span {
      color: #4372c4;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
.center {
  text-align: center;
}

.obstetrics-notice {
  margin: 5px;
  background-color: #fcf0f4;
  .notice-list {
    color: #f58b95;
  }
  .selectable-text {
    color: #0000FF;
    cursor: pointer;
    text-decoration: underline;
  }
}

.originalDiseaseField {
  position: relative;
  display: inline-block;

  .reflect {
    color: #3887e0;
    font-size: 80%;
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
  }
}

#originalDisease {
  border: solid 1px #000000;
  background-color: #ffffff;
}

.search-area {
  height: 280px;
  margin-left: 10px;
  margin-bottom: 20px;
  background-color: #fcf0f4;
}

#searchField {
  border: solid 1px #000000;
  background-color: #ffffff;
  width: 90%;
}

.resultField {
  position: relative;
}

.listWrap{
  width: 90%;
  z-index: 2;
  position:absolute;
  top: 0;
  left: 16px;

  .listArea {
    max-height: 150px;
    border: solid 1px #000000;
    margin:0 auto;
    overflow: auto;
    background-color: #ffffff;
  }

  p {
    margin: 0px;
    color: #0000FF;
    cursor: pointer;
    text-align: left;
    border: solid 1px #d2d2d2;
  }

  .itemNotice {
    color: #ffffff;
    background-color: #7f7f7f;
  }
}

.notice-text {
  margin: 0 auto;
  width: 85%;
  z-index: 1;
  position:absolute;
  top: 0;
  left: 24px;

  p {
    text-align: left;
    margin-top: 5px;
    margin-bottom: 0px;
  }
}
</style>
