import karteApi from "../../api/kartes";
import * as mutationTypes from "../mutation-types";


const state = {
  karteList: [],
  siblingKarteList: [],
  karte: {},
  karteImageList: [],
  karteReferenceInfo: [],
  karteUpdateResult: false,
  karteMediaFile: null,
};


const getters = {
  karteList: state => state.karteList,
  siblingKarteList: state => state.siblingKarteList,
  karte: state => state.karte,
  karteImageList: state => state.karteImageList,
  karteReferenceInfo: state => state.karteReferenceInfo,
  karteUpdateResult: state => state.karteUpdateResult,
  karteMediaFile: state => state.karteMediaFile,
};


const actions = {
  fetchKartes({ commit }, params) {
    return new Promise((resolve) => {
      karteApi.fetchKartes(karteList => {
        commit(mutationTypes.ASSIGN_KARTES, { karteList });
        resolve();
      }, params);
    });
  },

  fetchSiblingKartes({ commit }, params) {
    return new Promise((resolve) => {
      karteApi.fetchSiblingKartes(siblingKarteList => {
        commit(mutationTypes.ASSIGN_SIBLING_KARTES, { siblingKarteList });
        resolve();
      }, params);
    });
  },

  findKarte({ commit }, params) {
    return new Promise((resolve) => {
      karteApi.findKarte(karte => {
        commit(mutationTypes.ASSIGN_KARTE, { karte });
        resolve();
      }, params);
    });
  },

  updateKarte({ commit }, params) {
    return new Promise((resolve) => {
      karteApi.updateKarte(
        params,
        () => {
          let karteUpdateResult = true;
          commit(mutationTypes.KARTE_UPDATE_RESULT, { karteUpdateResult });
          resolve();
        },
        () => {
          let karteUpdateResult = false;
          commit(mutationTypes.KARTE_UPDATE_RESULT, { karteUpdateResult });
          resolve();
        });
    });
  },

  fetchKarteImages({ commit }, params) {
    return new Promise((resolve) => {
      karteApi.fetchKarteImages(karteImageList => {
        commit(mutationTypes.ASSIGN_KARTE_IMAGE_LIST, { karteImageList });
        resolve();
      }, params);
    });
  },

  findKarteReferenceInfo({ commit }, params) {
    return new Promise((resolve) => {
      karteApi.findKarteReferenceInfo(karteReferenceInfo => {
        commit(mutationTypes.ASSIGN_KARTE_REFERENCE_INFO, { karteReferenceInfo });
        resolve();
      }, params);
    });
  },

  uploadFile({ commit }, params) {
    return new Promise((resolve) => {
      karteApi.uploadFile(() => {
        karteApi.fetchKarteImages(karteImageList => {
          commit(mutationTypes.ASSIGN_KARTE_IMAGE_LIST, { karteImageList });
        }, { reservation_id: params.get("reservation_id") });
        resolve();
      }, params);
    });
  },

  deleteFile({ commit }, params) {
    return new Promise((resolve) => {
      karteApi.deleteFile(() => {
        karteApi.fetchKarteImages(karteImageList => {
          commit(mutationTypes.ASSIGN_KARTE_IMAGE_LIST, { karteImageList });
        }, params);
        resolve();
      }, params);
    });
  },

  viewFile({ commit }, mediaId) {
    return new Promise((resolve) => {
      karteApi.show( mediaFile => {
        commit(mutationTypes.ASSIGN_KARTE_MEDIA_FILE, { mediaFile });
        resolve();
      }, mediaId);
    });
  },

  clearKarte({ commit }) {
    let karte = { load_finish: false };
    commit(mutationTypes.ASSIGN_KARTE, { karte });
  },
  clearKartes({ commit }) {
    let karteList = [];
    commit(mutationTypes.ASSIGN_KARTES, { karteList });
  },
  clearSiblingKartes({ commit }) {
    let siblingKarteList = [];
    commit(mutationTypes.ASSIGN_SIBLING_KARTES, { siblingKarteList });
  }
};


const mutations = {
  [mutationTypes.ASSIGN_KARTES](state, { karteList }) {
    state.karteList = karteList;
  },

  [mutationTypes.ASSIGN_SIBLING_KARTES](state, { siblingKarteList }) {
    state.siblingKarteList = siblingKarteList;
  },

  [mutationTypes.ASSIGN_KARTE](state, { karte }) {
    state.karte = karte;
  },

  [mutationTypes.ASSIGN_KARTE_IMAGE_LIST](state, { karteImageList }) {
    state.karteImageList = karteImageList;
  },

  [mutationTypes.ASSIGN_KARTE_REFERENCE_INFO](state, { karteReferenceInfo }) {
    state.karteReferenceInfo = karteReferenceInfo;
  },

  [mutationTypes.KARTE_UPDATE_RESULT](state, { karteUpdateResult }) {
    state.karteUpdateResult = karteUpdateResult;
  },

  [mutationTypes.ASSIGN_KARTE_MEDIA_FILE](state, { mediaFile }) {
    state.karteMediaFile = mediaFile;
  },
};


export default {
  state,
  getters,
  actions,
  mutations
};
