<template>
  <div class="period">
    <v-list-item
      link
      :to="{ path: 'karte', query: { room: period.room, date: $route.query.date, reservation_id: period.id }}"
      :disabled="!period.reserved"
      :class="{ 'bg-pink-lighten-4': period.id == $route.query.reservation_id && period.id }"
      color="pink-lighten-5"
      :title="period.time"
      ripple
    />
    <v-divider />
  </div>
</template>

<script>
export default {
  name: "Period",
  props: {
    period: {
      type: Object,
      required: true
    },
  },
};
</script>

<style lang="scss">
  .period .v-list-item--link:before {
    background-color: transparent;
  }
  .period a.v-list-item--disabled {
    color: rgba(25, 118, 210, .37) !important;
  }
  .period .v-list-item {
    min-height: 40px;
  }
  .period .v-theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) .v-list-item-title {
    color: #e91e63 !important;
  }
  .period .v-list-item:not(.v-list-item--disabled) .v-list-item-title {
    color: #e91e63 !important;
  }

  .period hr {
    margin-top: 0;
    margin-bottom: 0;
  }
  .period .v-list-item-title {
    font-size: 1rem;
    color: #fce4ec !important;
    caret-color: #fce4ec !important;
  }
  .period a.bg-pink-lighten-4 div {
    color: #e91e63 !important;
  }
</style>
