<template>
  <v-container class="pa-0 pl-6 karte-addon">
    <!-- 運営からの特記事項 -->
    <operator-special-note :active="active" />
    <!-- ユーザの特記事項 -->
    <parents-special-note :active="active" />
    <!-- 子の特記事項 -->
    <child-special-note :active="active" />

    <!-- エジンバラ産後うつ質問票 -->
    <epds-list v-if="user.id != null" :user-id="user.id" />

    <my-karte-list
      :karte-list="karteList"
    />

    <sibling-karte-list
      title="関連するカルテ"
      :karte-list="siblingKarteList"
      :is-child-name-displayable="true"
    >
      <span class="subtitle-2">（他科・兄弟など）</span>
    </sibling-karte-list>

    <!--  日中助産師カルテ一覧  -->
    <daytime-advice-sheet-addon-list />

    <anytime-consultation-karte-list
      :title="'関連するいつでもカルテ一覧'"
      :anytime-consultations="userAnytimeConsultations"
      :is-child-name-displayable="true"
    />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import AnytimeConsultationKarteList from "./addon/AnytimeConsultationKarteList.vue";
import EpdsList from "./addon/EpdsList.vue";
import ParentsSpecialNote from "./addon/ParentsSpecialNote.vue";
import ChildSpecialNote from "./addon/ChildSpecialNote.vue";
import SiblingKarteList from "./addon/SiblingKarteList.vue";
import DaytimeAdviceSheetAddonList from "./addon/DaytimeAdviceSheetAddonList.vue";
import OperatorSpecialNote from "./addon/OperatorSpecialNote.vue";
import MyKarteList from "./addon/MyKarteList.vue";

export default {
  name: "KarteAddon",
  components: {
    AnytimeConsultationKarteList,
    EpdsList,
    ParentsSpecialNote,
    ChildSpecialNote,
    SiblingKarteList,
    DaytimeAdviceSheetAddonList,
    OperatorSpecialNote,
    MyKarteList
  },
  props: {
    active: Boolean,
    userId: {
      type: Number,
      required: true,
    },
    childId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data: () => ({
    cancelToken: axios.CancelToken,
    cancelSource: null,
  }),
  computed: {
    ...mapGetters([
      "user",
      "child",
      "karteList",
      "siblingKarteList",
      "anytimeConsultations",
      "userAnytimeConsultations",
      "reservation",
      "userDaytimeAdviceSheets",
    ]),
  },
  watch: {
    $route() {
      this.clearData();
      this.fetchData();
    },
    userId: {
      handler: "findUser",
      immediate: true,
    },
    childId: {
      handler: "findChild",
      immediate: true,
    },
  },
  created() {
    this.fetchData();
  },
  unmounted() {
    this.clearData();
  },
  methods: {
    ...mapActions(["updateUser", "updateChild"]),
    fetchData: function () {
      if (this.cancelSource) {
        this.cancelSource.cancel();
      }
      this.cancelSource = this.cancelToken.source();
      this.$store.dispatch("fetchKartes", {
        reservationId: this.$route.query.reservation_id,
        cancelToken: this.cancelSource.token,
      });
      this.$store.dispatch("fetchSiblingKartes", {
        reservationId: this.$route.query.reservation_id,
        cancelToken: this.cancelSource.token,
      });
      if (this.userId != null) {
        this.$store.dispatch("fetchUserDaytimeAdviceSheets", {
          userId: this.userId,
          cancelToken: this.cancelSource.token,
        });
        this.$store.dispatch("fetchUserAnytimeConsultations", {
          userId: this.userId,
          cancelToken: this.cancelSource.token,
        });
      }
    },
    clearData() {
      this.$store.dispatch("resetUser");
      this.$store.dispatch("resetChild");
      this.$store.dispatch("clearUserDaytimeAdviceSheets");
      this.$store.dispatch("clearUserAnytimeConsultations");
      this.$store.dispatch("clearKartes");
      this.$store.dispatch("clearSiblingKartes");
    },
    findUser() {
      if (this.userId != null) {
        this.$store.dispatch("findUser", {
          userId: this.userId,
        });
      }
    },
    findChild() {
      if (this.childId != null) {
        this.$store.dispatch("findChild", {
          childId: this.childId,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/* override */
.v-toolbar__title {
  color: #fff;
  font-size: 14px;
}
</style>
