<template>
  <v-container
    fluid
    class="daytime-advice-room pa-2"
  >
    <v-row
      class="pa-2"
      align-content="center"
    >
      <v-col
        cols="2"
        sm="2"
        md="2"
        class="pa-0 pl-4"
      >
        <v-row
          align-content="center"
        >
          <v-col>
            <template v-if="waiting()">
              <v-btn
                v-if="waiting()"
                color="pink"
                class="font-weight-bold ml-0 white--text"
                block
                light
                :disabled="isProcessing()"
                @click="startDaytimeAdvice()"
              >
                <v-icon
                  icon="mdi-message-text"
                />
                日中助産師 担当開始
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                :color="isReady() && hasNoInProcessSheet() ? 'indigo' : 'grey'"
                class="font-weight-bold ml-0 white--text"
                light
                block
                :disabled="isProcessing()"
                @click="finishDaytimeAdvice()"
              >
                <v-icon
                  icon="mdi-message-bulleted-off"
                />
                日中助産師 担当終了
              </v-btn>
            </template>
          </v-col>
        </v-row>
        <v-row
          align-content="center"
        >
          <v-col>
            <span style="font-size:12px;font-weight:bold;">相談一覧</span>
          </v-col>
        </v-row>
        <v-card>
          <v-list
            class="pt-0 pb-0 sheet-list"
          >
            <div
              v-for="sheet in assignedAdviceSheets"
              :key="sheet.id"
              class="sheet-list-item"
            >
              <v-list-item
                link
                :to="{ name: 'daytimeAdviceSheet', params: { id: sheet.id }}"
                active-class="pink-lighten-4"
                ripple
                two-line
                height="65"
              >
                <div>
                  <v-list-item-title>
                    <span>
                      {{ processStatus(sheet) }}  {{ sheet.userName }}
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-if="!activeSheet(sheet.id)"
                    class="text-right"
                  >
                    <span
                      v-if="sheet.lastSender == 'user'"
                      class="text-pink caption"
                    >
                      <i class="mdi mdi-circle" /> ({{ sheet.lastSentAt }})
                    </span>
                    <span
                      v-if="sheet.lastSender != 'user'"
                      class="dark-grey--text caption"
                    >
                      ({{ sheet.lastSentAt }})
                    </span>
                  </v-list-item-subtitle>
                </div>
              </v-list-item>
              <v-divider />
            </div>
          </v-list>
        </v-card>
        <div class="pa-3">
          <span
            class="text-pink text-4"
            style="font-weight: bold;"
          >
            現在のプーリング: {{ poolingCount }}件
          </span>
        </div>
      </v-col>
      <v-col
        cols="10"
        sm="10"
        md="10"
        class="pa-0"
      >
        <router-view class="daytime_advice_sheet" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import timestampMixin from "../mixins/timestamp.js";
import processingMixin from "../mixins/processing.js";

export default {
  name: "DaytimeAdviceRoom",
  mixins: [timestampMixin, processingMixin],
  data: () => ({
    messageChannel: null,
    noticeSheetIds: [],
  }),
  computed: {
    ...mapGetters([
      "currentDoctor",
      "assignedAdviceSheets",
      "assignedAdviceTimeoutId",
      "poolingCount"
    ]),
  },
  watch: {
    "$route" () {
      this.refresh();
      if(this.$route.params.id != null){
        this.noticeSheetIds = this.noticeSheetIds.filter(id => id !== parseInt(this.$route.params.id));
      }
    }
  },
  created() {
    this.refresh(this.connectionNoticeChannel);
  },
  unmounted() {
    // 自動更新を停止する
    clearInterval(this.assignedAdviceTimeoutId);
  },
  methods: {
    isOperator: function(){
      return this.currentDoctor && this.currentDoctor.type == "Operator::Doctor";
    },
    startDaytimeAdvice: function(){
      this.startProcessing();
      this.$store.dispatch("daytimeAdviceStart").then(() => {
        this.refresh(this.endProcessing);
      });
    },
    finishDaytimeAdvice: function(){
      this.startProcessing();
      this.$store.dispatch("daytimeAdviceFinish").then(() => {
        this.refresh(this.endProcessing);
      }).catch(() => {
        this.endProcessing();
      });
    },
    refresh: function(afterAction){
      this.$store.dispatch("fetchCurrentDoctor").then(() => {
        this.startAutoReload();
        if(afterAction != null){
          afterAction();
        }
      });
    },
    startAutoReload: function(){
      // 以前設定されたtimeoutがあればクリア
      clearInterval(this.assignedAdviceTimeoutId);
      // 最初の1回の実行
      this.fetchData();
      // 30秒
      var timeoutId = setInterval(this.fetchData, 30 * 1000);
      this.$store.dispatch("assignAssignedAdviceTimeoutId", timeoutId);
    },
    fetchData: function(){
      this.$store.dispatch("fetchAssignedAdviceSheets");
      this.$store.dispatch("fetchPoolingCount");
    },
    isReady: function(){
      return this.currentDoctor && this.currentDoctor.daytime_advice_active == true;
    },
    waiting: function(){
      return this.currentDoctor && this.currentDoctor.daytime_advice_active == false;
    },
    processStatus: function(sheet){
      return sheet.statusI18n;
    },
    hasNoInProcessSheet: function(){
      // 対応中の相談がある場合、担当終了ボタンを表示させない
      return this.assignedAdviceSheets.find(sheet => sheet.status == 2) == null;
    },
    connectionNoticeChannel: function(){
      console.log(this.timestamp() + " connection Daytime Advice Notice Message Channel.");
      var that = this;
      this.disConnectionNoticeChannel();
      this.messageChannel = this.$cable.subscriptions.create(
        {
          channel: "DaytimeAdviceNoticeChannel",
        },
        {
          received: (data) =>  {
            console.log(that.timestamp() + " received Daytime Advice notice message.");
            var noticeInfo = JSON.parse(data["notice"]);
            // 通知対象の相談IDを保存する
            if(this.$route.params.id != noticeInfo.sheetId){
              if(this.noticeSheetIds.indexOf(noticeInfo.sheetId) == -1){
                this.noticeSheetIds.push(noticeInfo.sheetId);
              }
              // 最新データを取得し直す
              this.fetchData();
            }
          },
          connected: function() {
            console.log(that.timestamp() + " connected Daytime Advice notice message channel.");
          },
          disconnected: function() {
            console.log(that.timestamp() + " disconnected Daytime Advice notice message channel.");
          },
          rejected: function() {
            console.log(that.timestamp() + " rejected Daytime Advice notice message channel.");
          },
        }
      );
    },
    disConnectionNoticeChannel: function(){
      if(this.messageChannel == null) return;
      console.log(this.timestamp() + " disconnection Daytime Advice notice message Channel");
      this.messageChannel.unsubscribe();
    },
    activeSheet: function(id){
      return this.$route.params.id == id;
    }
  }
};
</script>
<style lang="scss" scoped>
.v-list-item__title {
  font-size: 14px;
}
.pink-lighten-4 {
  background-color: #fce4ec !important;
}
</style>
